import { OutboundProcess } from '@features/supplyChain/types';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  supplyChainProcessId: Yup.string(),
  despatchNoteId: Yup.string(),
  pickingDateConfirmation: Yup.string().required(
    'A picking date confirmation is required.'
  ),
});

export const makeDefaultValues = (selectedOutboundProcess: OutboundProcess) => {
  const { despatchNote } = selectedOutboundProcess;
  return {
    supplyChainProcessId: selectedOutboundProcess.id,
    despatchNoteId: despatchNote.id,
    pickingDateConfirmation: dayjs(
      selectedOutboundProcess.despatchNote.requiredTimeOfArrival
    ).format(),
  };
};
