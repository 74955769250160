import { Status, ShipmentType } from '@views/Shipments/types';

// Version
export const VERSION = '/version';
export const BACKEND_VERSION = '/version/backend';

// Logos
export const FLOX_LOGO_BLUE_SRC = '/images/flox-logo-blue.svg';
export const FLOX_LOGO_STANDARD_REVERSE_SRC =
  '/images/flox-logo-standard-reverse.svg';

// Login / Registration
export const LOGIN_WITH_CREDENTIALS_URL = '/Auth/LoginWithCredentials';
export const REGISTRATION_URL = '/Auth/Register';
export const FORGOTTEN_PASSWORD_URL = '/Auth/SendForgottenPasswordLink';
export const RESET_PASSWORD_URL = '/Auth/SetNewPasswordWithToken';
export const REFRESH_TOKEN_URL = '/Auth/RefreshToken';
export const CONFIRM_EMAIL_URL = '/Auth/ConfirmEmail';
export const INVITE_TEAM_MEMBER_URL = '/Auth/InviteTeamMember';
export const JOIN_URL = '/Auth/Join';
export const LIST_USERS_URL = '/Auth/ListUsers';
export const DEACTIVATE_TEAM_MEMBER_URL = '/Auth/DeactivateTeamMember';
export const ACTIVATE_TEAM_MEMBER_URL = '/Auth/ActivateTeamMember';
export const GET_TWO_FACTOR_SECRET_URL = '/Auth/GetTwoFactorSecret';
export const ENABLE_TWO_FACTOR_AUTH_URL = '/Auth/EnableTwoFactorAuth';
export const LOGIN_WITH_TWO_FACTOR_AUTH_URL = '/Auth/LoginWithTwoFactorAuth';

// Inventory
export const CREATE_PRODUCT_URL = '/Inventory/Create';
export const UPDATE_PRODUCT_URL = '/Inventory/Update';
export const GET_PRODUCT_URL = '/Inventory/Get';
export const LIST_PRODUCTS_URL = '/Inventory/List/Products';
export const INVENTORY_IMAGES_URL =
  import.meta.env.MODE === 'production'
    ? 'https://static.flox.is'
    : 'http://localhost:8080/download/storage/v1/b/flox-static/o';

// Warehouses
export const AVAILABLE_WAREHOUSES_URL = '/Match/List/Contracted';
export const LSP_WAREHOUSES_URL = '/Warehouse/List';
export const WAREHOUSE_SEARCH_URL = '/Warehouse/Search';
export const WAREHOUSE_CREATE = '/Warehouse/Create';
export const WAREHOUSE_UPDATE = '/Warehouse/Update';
export const WAREHOUSE_GET = '/Warehouse/Get';
export const WAREHOUSE_SEARCH_GET = '/Warehouse/Search';
export const WAREHOUSE_DELIST = '/Warehouse/Delist';
export const HERE_APIKEY = 'f0tGXxtAhk_uegH03IpLQK73fvVHaX3g8No3TaEjVmM';
export const WAREHOUSE_IMAGES_URL =
  import.meta.env.MODE === 'production'
    ? 'https://static.flox.is'
    : 'http://localhost:8080/download/storage/v1/b/flox-static/o';

// Fleet
export const FLEET_GET = '/Vehicle/List/Vehicles';
export const VEHICLE_CREATE = '/Vehicle/Create';
export const VEHICLE_UPDATE = '/Vehicle/Update';
export const VEHICLE_GET = '/Vehicle/Get';
export const VEHICLE_CATEGORIES = '/Vehicle/Categories';
export const FLEET_IMAGES_URL =
  import.meta.env.MODE === 'production'
    ? 'https://static.flox.is'
    : 'http://localhost:8080/download/storage/v1/b/flox-static/o';
export const UPLOAD_FLEET_MASTER_URL = '/Vehicle/BulkCreate';
export const FLEET_AVAILABILITY = '/VehicleAvailability/List';
export const FLEET_AVAILABILITY_CREATE = '/VehicleAvailability/Create';
export const FLEET_AVAILABILITY_UPDATE = '/VehicleAvailability/Update';

// Ratecards
export const RATECARDS_GET = '/Pricing/List/Ratecards';
export const DAILY_RATECARD_CREATE = '/Pricing/Create/DailyRatecard';
export const POSTCODE_RATECARD_CREATE = '/Pricing/Import/PostcodeRatecard';
export const ADDITIONAL_FTL_RATECARD_CREATE =
  '/Pricing/Import/AdditionalLoadRatecard';
export const MULTISTOP_RATECARD_CREATE = '/Pricing/Create/MultiStopRatecard';

// Customer Master
export const CUSTOMER_GET = '/Customer/List';
export const CUSTOMER_CREATE = '/Customer/Create';

// Supplier Master
export const SUPPLIER_GET = '/Supplier/List';
export const SUPPLIER_CREATE = '/Supplier/Create';

// Clients
export const MATCH_CLIENTS_URL = '/Match/Clients';

// Profile
export const BUSINESS_PROFILE_GET = '/BusinessProfile/Get';
export const BUSINESS_PROFILE_CREATE = '/BusinessProfile/Create';
export const BUSINESS_PROFILE_UPDATE = '/BusinessProfile/Update';
export const BUSINESS_PROFILE_AGREE_WAREHOUSE_TANDCS =
  '/BusinessProfile/AgreeToWarehouseTandCs';
export const STARTER_CHECKLIST_GET = '/BusinessProfile/GetStarterChecklist';

// Matching
export const MATCH_LIST = '/Match/List';
export const CONTRACTED_MATCH_LIST = '/Match/List/Contracted';
export const ADD_WAREHOUSE_MATCH = `/Match/AddWarehouseMatch`;
export const DELETE_WAREHOUSE_MATCH = `/Match/DeleteWarehouseMatch`;
export const PROPOSE_WAREHOUSE_MATCH_DETAILS = `/Match/ProposeWarehouseMatchDetails`;
export const ACCEPT_WAREHOUSE_MATCH = `/Match/AcceptWarehouseMatch`;
export const LIST_WAREHOUSE_MATCH_DOCUMENTS = `/Match/ListWarehouseMatchDocuments`;

// SKUs
export const AVAILABLE_SKUS_URL = '/Inventory/List/Skus';
export const UPLOAD_PRODUCT_MASTER_URL = '/Inventory/BulkCreate';

// Shipment notes
export const CREATE_INBOUND_SHIPMENT_NOTE_URL =
  '/SupplyChainProcess/CreateInboundShipmentNote';
export const CREATE_OUTBOUND_SHIPMENT_NOTE_URL =
  '/SupplyChainProcess/CreateOutboundShipmentNote';

export const INBOUND_SHIPMENT_TYPE = 'Inbound';
export const OUTBOUND_SHIPMENT_TYPE = 'Outbound';

// Supply chain process
export const LIST_SUPPLY_CHAIN_PROCESSES_URL = '/SupplyChainProcess/List';
export const APPROVED_INBOUND_SHIPMENT_NOTE_URL =
  '/SupplyChainProcess/ApproveInboundShipmentNote';
export const FINALISE_GOODS_RECEIVED_NOTE_URL =
  '/SupplyChainProcess/FinaliseGoodsReceivedNote';
export const CREATE_DESPATCH_NOTE = '/SupplyChainProcess/CreateDespatchNote';
export const AUTHORISE_FOR_PICKING =
  '/SupplyChainProcess/AuthoriseDespatchNote';
export const CONFIRM_PICKING_NOTE = '/SupplyChainProcess/ConfirmPickingNote';
export const CREATE_PROOF_OF_DELIVERY =
  '/SupplyChainProcess/ApproveOutboundShipmentNote';
export const SUGGESTED_ID_URL =
  '/SupplyChainProcess/GetSupplyChainProcessIdSuggestion';

// Search
export const MACRO_SEARCH_URL = '/Search/Macro';

// Misc settings
export const DEBOUNCE_TIME_IN_MS = 500;

// User roles
export const WAREHOUSE_CLIENT = 'WarehouseClient';
export const WAREHOUSE_MANAGER = 'WarehouseManager';
export const WAREHOUSE_OPERATOR = 'WarehouseOperator';

// Notifications
export const GET_NOTIFICATIONS = '/Notification/List';

// Currencies
export const SUPPORTED_CURRENCY_GROUPS = [
  {
    groupName: 'Popular currencies',
    currencies: [
      {
        symbol: '£',
        name: 'British Pound Sterling',
        symbol_native: '£',
        decimal_digits: 2,
        rounding: 0,
        code: 'GBP',
        name_plural: 'British pounds sterling',
      },
      {
        symbol: '€',
        name: 'Euro',
        symbol_native: '€',
        decimal_digits: 2,
        rounding: 0,
        code: 'EUR',
        name_plural: 'euros',
      },
      {
        symbol: '$',
        name: 'US Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'USD',
        name_plural: 'US dollars',
      },
    ],
  },
  {
    groupName: 'More currencies',
    currencies: [
      {
        symbol: 'CA$',
        name: 'Canadian Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'CAD',
        name_plural: 'Canadian dollars',
      },
      {
        symbol: 'AED',
        name: 'United Arab Emirates Dirham',
        symbol_native: 'د.إ.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'AED',
        name_plural: 'UAE dirhams',
      },
      {
        symbol: 'Af',
        name: 'Afghan Afghani',
        symbol_native: '؋',
        decimal_digits: 0,
        rounding: 0,
        code: 'AFN',
        name_plural: 'Afghan Afghanis',
      },
      {
        symbol: 'ALL',
        name: 'Albanian Lek',
        symbol_native: 'Lek',
        decimal_digits: 0,
        rounding: 0,
        code: 'ALL',
        name_plural: 'Albanian lekë',
      },
      {
        symbol: 'AMD',
        name: 'Armenian Dram',
        symbol_native: 'դր.',
        decimal_digits: 0,
        rounding: 0,
        code: 'AMD',
        name_plural: 'Armenian drams',
      },
      {
        symbol: 'AR$',
        name: 'Argentine Peso',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'ARS',
        name_plural: 'Argentine pesos',
      },
      {
        symbol: 'AU$',
        name: 'Australian Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'AUD',
        name_plural: 'Australian dollars',
      },
      {
        symbol: 'man.',
        name: 'Azerbaijani Manat',
        symbol_native: 'ман.',
        decimal_digits: 2,
        rounding: 0,
        code: 'AZN',
        name_plural: 'Azerbaijani manats',
      },
      {
        symbol: 'KM',
        name: 'Bosnia-Herzegovina Convertible Mark',
        symbol_native: 'KM',
        decimal_digits: 2,
        rounding: 0,
        code: 'BAM',
        name_plural: 'Bosnia-Herzegovina convertible marks',
      },
      {
        symbol: 'Tk',
        name: 'Bangladeshi Taka',
        symbol_native: '৳',
        decimal_digits: 2,
        rounding: 0,
        code: 'BDT',
        name_plural: 'Bangladeshi takas',
      },
      {
        symbol: 'BGN',
        name: 'Bulgarian Lev',
        symbol_native: 'лв.',
        decimal_digits: 2,
        rounding: 0,
        code: 'BGN',
        name_plural: 'Bulgarian leva',
      },
      {
        symbol: 'BD',
        name: 'Bahraini Dinar',
        symbol_native: 'د.ب.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'BHD',
        name_plural: 'Bahraini dinars',
      },
      {
        symbol: 'FBu',
        name: 'Burundian Franc',
        symbol_native: 'FBu',
        decimal_digits: 0,
        rounding: 0,
        code: 'BIF',
        name_plural: 'Burundian francs',
      },
      {
        symbol: 'BN$',
        name: 'Brunei Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'BND',
        name_plural: 'Brunei dollars',
      },
      {
        symbol: 'Bs',
        name: 'Bolivian Boliviano',
        symbol_native: 'Bs',
        decimal_digits: 2,
        rounding: 0,
        code: 'BOB',
        name_plural: 'Bolivian bolivianos',
      },
      {
        symbol: 'R$',
        name: 'Brazilian Real',
        symbol_native: 'R$',
        decimal_digits: 2,
        rounding: 0,
        code: 'BRL',
        name_plural: 'Brazilian reals',
      },
      {
        symbol: 'BWP',
        name: 'Botswanan Pula',
        symbol_native: 'P',
        decimal_digits: 2,
        rounding: 0,
        code: 'BWP',
        name_plural: 'Botswanan pulas',
      },
      {
        symbol: 'BYR',
        name: 'Belarusian Ruble',
        symbol_native: 'BYR',
        decimal_digits: 0,
        rounding: 0,
        code: 'BYR',
        name_plural: 'Belarusian rubles',
      },
      {
        symbol: 'BZ$',
        name: 'Belize Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'BZD',
        name_plural: 'Belize dollars',
      },
      {
        symbol: 'CDF',
        name: 'Congolese Franc',
        symbol_native: 'FrCD',
        decimal_digits: 2,
        rounding: 0,
        code: 'CDF',
        name_plural: 'Congolese francs',
      },
      {
        symbol: 'CHF',
        name: 'Swiss Franc',
        symbol_native: 'CHF',
        decimal_digits: 2,
        rounding: 0.05,
        code: 'CHF',
        name_plural: 'Swiss francs',
      },
      {
        symbol: 'CL$',
        name: 'Chilean Peso',
        symbol_native: '$',
        decimal_digits: 0,
        rounding: 0,
        code: 'CLP',
        name_plural: 'Chilean pesos',
      },
      {
        symbol: 'CN¥',
        name: 'Chinese Yuan',
        symbol_native: 'CN¥',
        decimal_digits: 2,
        rounding: 0,
        code: 'CNY',
        name_plural: 'Chinese yuan',
      },
      {
        symbol: 'CO$',
        name: 'Colombian Peso',
        symbol_native: '$',
        decimal_digits: 0,
        rounding: 0,
        code: 'COP',
        name_plural: 'Colombian pesos',
      },
      {
        symbol: '₡',
        name: 'Costa Rican Colón',
        symbol_native: '₡',
        decimal_digits: 0,
        rounding: 0,
        code: 'CRC',
        name_plural: 'Costa Rican colóns',
      },
      {
        symbol: 'CV$',
        name: 'Cape Verdean Escudo',
        symbol_native: 'CV$',
        decimal_digits: 2,
        rounding: 0,
        code: 'CVE',
        name_plural: 'Cape Verdean escudos',
      },
      {
        symbol: 'Kč',
        name: 'Czech Republic Koruna',
        symbol_native: 'Kč',
        decimal_digits: 2,
        rounding: 0,
        code: 'CZK',
        name_plural: 'Czech Republic korunas',
      },
      {
        symbol: 'Fdj',
        name: 'Djiboutian Franc',
        symbol_native: 'Fdj',
        decimal_digits: 0,
        rounding: 0,
        code: 'DJF',
        name_plural: 'Djiboutian francs',
      },
      {
        symbol: 'Dkr',
        name: 'Danish Krone',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'DKK',
        name_plural: 'Danish kroner',
      },
      {
        symbol: 'RD$',
        name: 'Dominican Peso',
        symbol_native: 'RD$',
        decimal_digits: 2,
        rounding: 0,
        code: 'DOP',
        name_plural: 'Dominican pesos',
      },
      {
        symbol: 'DA',
        name: 'Algerian Dinar',
        symbol_native: 'د.ج.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'DZD',
        name_plural: 'Algerian dinars',
      },
      {
        symbol: 'Ekr',
        name: 'Estonian Kroon',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'EEK',
        name_plural: 'Estonian kroons',
      },
      {
        symbol: 'EGP',
        name: 'Egyptian Pound',
        symbol_native: 'ج.م.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'EGP',
        name_plural: 'Egyptian pounds',
      },
      {
        symbol: 'Nfk',
        name: 'Eritrean Nakfa',
        symbol_native: 'Nfk',
        decimal_digits: 2,
        rounding: 0,
        code: 'ERN',
        name_plural: 'Eritrean nakfas',
      },
      {
        symbol: 'Br',
        name: 'Ethiopian Birr',
        symbol_native: 'Br',
        decimal_digits: 2,
        rounding: 0,
        code: 'ETB',
        name_plural: 'Ethiopian birrs',
      },
      {
        symbol: 'GEL',
        name: 'Georgian Lari',
        symbol_native: 'GEL',
        decimal_digits: 2,
        rounding: 0,
        code: 'GEL',
        name_plural: 'Georgian laris',
      },
      {
        symbol: 'GH₵',
        name: 'Ghanaian Cedi',
        symbol_native: 'GH₵',
        decimal_digits: 2,
        rounding: 0,
        code: 'GHS',
        name_plural: 'Ghanaian cedis',
      },
      {
        symbol: 'FG',
        name: 'Guinean Franc',
        symbol_native: 'FG',
        decimal_digits: 0,
        rounding: 0,
        code: 'GNF',
        name_plural: 'Guinean francs',
      },
      {
        symbol: 'GTQ',
        name: 'Guatemalan Quetzal',
        symbol_native: 'Q',
        decimal_digits: 2,
        rounding: 0,
        code: 'GTQ',
        name_plural: 'Guatemalan quetzals',
      },
      {
        symbol: 'HK$',
        name: 'Hong Kong Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'HKD',
        name_plural: 'Hong Kong dollars',
      },
      {
        symbol: 'HNL',
        name: 'Honduran Lempira',
        symbol_native: 'L',
        decimal_digits: 2,
        rounding: 0,
        code: 'HNL',
        name_plural: 'Honduran lempiras',
      },
      {
        symbol: 'kn',
        name: 'Croatian Kuna',
        symbol_native: 'kn',
        decimal_digits: 2,
        rounding: 0,
        code: 'HRK',
        name_plural: 'Croatian kunas',
      },
      {
        symbol: 'Ft',
        name: 'Hungarian Forint',
        symbol_native: 'Ft',
        decimal_digits: 0,
        rounding: 0,
        code: 'HUF',
        name_plural: 'Hungarian forints',
      },
      {
        symbol: 'Rp',
        name: 'Indonesian Rupiah',
        symbol_native: 'Rp',
        decimal_digits: 0,
        rounding: 0,
        code: 'IDR',
        name_plural: 'Indonesian rupiahs',
      },
      {
        symbol: '₪',
        name: 'Israeli New Sheqel',
        symbol_native: '₪',
        decimal_digits: 2,
        rounding: 0,
        code: 'ILS',
        name_plural: 'Israeli new sheqels',
      },
      {
        symbol: 'Rs',
        name: 'Indian Rupee',
        symbol_native: 'টকা',
        decimal_digits: 2,
        rounding: 0,
        code: 'INR',
        name_plural: 'Indian rupees',
      },
      {
        symbol: 'IQD',
        name: 'Iraqi Dinar',
        symbol_native: 'د.ع.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'IQD',
        name_plural: 'Iraqi dinars',
      },
      {
        symbol: 'IRR',
        name: 'Iranian Rial',
        symbol_native: '﷼',
        decimal_digits: 0,
        rounding: 0,
        code: 'IRR',
        name_plural: 'Iranian rials',
      },
      {
        symbol: 'Ikr',
        name: 'Icelandic Króna',
        symbol_native: 'kr',
        decimal_digits: 0,
        rounding: 0,
        code: 'ISK',
        name_plural: 'Icelandic krónur',
      },
      {
        symbol: 'J$',
        name: 'Jamaican Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'JMD',
        name_plural: 'Jamaican dollars',
      },
      {
        symbol: 'JD',
        name: 'Jordanian Dinar',
        symbol_native: 'د.أ.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'JOD',
        name_plural: 'Jordanian dinars',
      },
      {
        symbol: '¥',
        name: 'Japanese Yen',
        symbol_native: '￥',
        decimal_digits: 0,
        rounding: 0,
        code: 'JPY',
        name_plural: 'Japanese yen',
      },
      {
        symbol: 'Ksh',
        name: 'Kenyan Shilling',
        symbol_native: 'Ksh',
        decimal_digits: 2,
        rounding: 0,
        code: 'KES',
        name_plural: 'Kenyan shillings',
      },
      {
        symbol: 'KHR',
        name: 'Cambodian Riel',
        symbol_native: '៛',
        decimal_digits: 2,
        rounding: 0,
        code: 'KHR',
        name_plural: 'Cambodian riels',
      },
      {
        symbol: 'CF',
        name: 'Comorian Franc',
        symbol_native: 'FC',
        decimal_digits: 0,
        rounding: 0,
        code: 'KMF',
        name_plural: 'Comorian francs',
      },
      {
        symbol: '₩',
        name: 'South Korean Won',
        symbol_native: '₩',
        decimal_digits: 0,
        rounding: 0,
        code: 'KRW',
        name_plural: 'South Korean won',
      },
      {
        symbol: 'KD',
        name: 'Kuwaiti Dinar',
        symbol_native: 'د.ك.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'KWD',
        name_plural: 'Kuwaiti dinars',
      },
      {
        symbol: 'KZT',
        name: 'Kazakhstani Tenge',
        symbol_native: 'тңг.',
        decimal_digits: 2,
        rounding: 0,
        code: 'KZT',
        name_plural: 'Kazakhstani tenges',
      },
      {
        symbol: 'LB£',
        name: 'Lebanese Pound',
        symbol_native: 'ل.ل.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'LBP',
        name_plural: 'Lebanese pounds',
      },
      {
        symbol: 'SLRs',
        name: 'Sri Lankan Rupee',
        symbol_native: 'SL Re',
        decimal_digits: 2,
        rounding: 0,
        code: 'LKR',
        name_plural: 'Sri Lankan rupees',
      },
      {
        symbol: 'Lt',
        name: 'Lithuanian Litas',
        symbol_native: 'Lt',
        decimal_digits: 2,
        rounding: 0,
        code: 'LTL',
        name_plural: 'Lithuanian litai',
      },
      {
        symbol: 'Ls',
        name: 'Latvian Lats',
        symbol_native: 'Ls',
        decimal_digits: 2,
        rounding: 0,
        code: 'LVL',
        name_plural: 'Latvian lati',
      },
      {
        symbol: 'LD',
        name: 'Libyan Dinar',
        symbol_native: 'د.ل.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'LYD',
        name_plural: 'Libyan dinars',
      },
      {
        symbol: 'MAD',
        name: 'Moroccan Dirham',
        symbol_native: 'د.م.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'MAD',
        name_plural: 'Moroccan dirhams',
      },
      {
        symbol: 'MDL',
        name: 'Moldovan Leu',
        symbol_native: 'MDL',
        decimal_digits: 2,
        rounding: 0,
        code: 'MDL',
        name_plural: 'Moldovan lei',
      },
      {
        symbol: 'MGA',
        name: 'Malagasy Ariary',
        symbol_native: 'MGA',
        decimal_digits: 0,
        rounding: 0,
        code: 'MGA',
        name_plural: 'Malagasy Ariaries',
      },
      {
        symbol: 'MKD',
        name: 'Macedonian Denar',
        symbol_native: 'MKD',
        decimal_digits: 2,
        rounding: 0,
        code: 'MKD',
        name_plural: 'Macedonian denari',
      },
      {
        symbol: 'MMK',
        name: 'Myanma Kyat',
        symbol_native: 'K',
        decimal_digits: 0,
        rounding: 0,
        code: 'MMK',
        name_plural: 'Myanma kyats',
      },
      {
        symbol: 'MOP$',
        name: 'Macanese Pataca',
        symbol_native: 'MOP$',
        decimal_digits: 2,
        rounding: 0,
        code: 'MOP',
        name_plural: 'Macanese patacas',
      },
      {
        symbol: 'MURs',
        name: 'Mauritian Rupee',
        symbol_native: 'MURs',
        decimal_digits: 0,
        rounding: 0,
        code: 'MUR',
        name_plural: 'Mauritian rupees',
      },
      {
        symbol: 'MX$',
        name: 'Mexican Peso',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'MXN',
        name_plural: 'Mexican pesos',
      },
      {
        symbol: 'RM',
        name: 'Malaysian Ringgit',
        symbol_native: 'RM',
        decimal_digits: 2,
        rounding: 0,
        code: 'MYR',
        name_plural: 'Malaysian ringgits',
      },
      {
        symbol: 'MTn',
        name: 'Mozambican Metical',
        symbol_native: 'MTn',
        decimal_digits: 2,
        rounding: 0,
        code: 'MZN',
        name_plural: 'Mozambican meticals',
      },
      {
        symbol: 'N$',
        name: 'Namibian Dollar',
        symbol_native: 'N$',
        decimal_digits: 2,
        rounding: 0,
        code: 'NAD',
        name_plural: 'Namibian dollars',
      },
      {
        symbol: '₦',
        name: 'Nigerian Naira',
        symbol_native: '₦',
        decimal_digits: 2,
        rounding: 0,
        code: 'NGN',
        name_plural: 'Nigerian nairas',
      },
      {
        symbol: 'C$',
        name: 'Nicaraguan Córdoba',
        symbol_native: 'C$',
        decimal_digits: 2,
        rounding: 0,
        code: 'NIO',
        name_plural: 'Nicaraguan córdobas',
      },
      {
        symbol: 'Nkr',
        name: 'Norwegian Krone',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'NOK',
        name_plural: 'Norwegian kroner',
      },
      {
        symbol: 'NPRs',
        name: 'Nepalese Rupee',
        symbol_native: 'नेरू',
        decimal_digits: 2,
        rounding: 0,
        code: 'NPR',
        name_plural: 'Nepalese rupees',
      },
      {
        symbol: 'NZ$',
        name: 'New Zealand Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'NZD',
        name_plural: 'New Zealand dollars',
      },
      {
        symbol: 'OMR',
        name: 'Omani Rial',
        symbol_native: 'ر.ع.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'OMR',
        name_plural: 'Omani rials',
      },
      {
        symbol: 'B/.',
        name: 'Panamanian Balboa',
        symbol_native: 'B/.',
        decimal_digits: 2,
        rounding: 0,
        code: 'PAB',
        name_plural: 'Panamanian balboas',
      },
      {
        symbol: 'S/.',
        name: 'Peruvian Nuevo Sol',
        symbol_native: 'S/.',
        decimal_digits: 2,
        rounding: 0,
        code: 'PEN',
        name_plural: 'Peruvian nuevos soles',
      },
      {
        symbol: '₱',
        name: 'Philippine Peso',
        symbol_native: '₱',
        decimal_digits: 2,
        rounding: 0,
        code: 'PHP',
        name_plural: 'Philippine pesos',
      },
      {
        symbol: 'PKRs',
        name: 'Pakistani Rupee',
        symbol_native: '₨',
        decimal_digits: 0,
        rounding: 0,
        code: 'PKR',
        name_plural: 'Pakistani rupees',
      },
      {
        symbol: 'zł',
        name: 'Polish Zloty',
        symbol_native: 'zł',
        decimal_digits: 2,
        rounding: 0,
        code: 'PLN',
        name_plural: 'Polish zlotys',
      },
      {
        symbol: '₲',
        name: 'Paraguayan Guarani',
        symbol_native: '₲',
        decimal_digits: 0,
        rounding: 0,
        code: 'PYG',
        name_plural: 'Paraguayan guaranis',
      },
      {
        symbol: 'QR',
        name: 'Qatari Rial',
        symbol_native: 'ر.ق.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'QAR',
        name_plural: 'Qatari rials',
      },
      {
        symbol: 'RON',
        name: 'Romanian Leu',
        symbol_native: 'RON',
        decimal_digits: 2,
        rounding: 0,
        code: 'RON',
        name_plural: 'Romanian lei',
      },
      {
        symbol: 'din.',
        name: 'Serbian Dinar',
        symbol_native: 'дин.',
        decimal_digits: 0,
        rounding: 0,
        code: 'RSD',
        name_plural: 'Serbian dinars',
      },
      {
        symbol: 'RUB',
        name: 'Russian Ruble',
        symbol_native: 'руб.',
        decimal_digits: 2,
        rounding: 0,
        code: 'RUB',
        name_plural: 'Russian rubles',
      },
      {
        symbol: 'RWF',
        name: 'Rwandan Franc',
        symbol_native: 'FR',
        decimal_digits: 0,
        rounding: 0,
        code: 'RWF',
        name_plural: 'Rwandan francs',
      },
      {
        symbol: 'SR',
        name: 'Saudi Riyal',
        symbol_native: 'ر.س.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'SAR',
        name_plural: 'Saudi riyals',
      },
      {
        symbol: 'SDG',
        name: 'Sudanese Pound',
        symbol_native: 'SDG',
        decimal_digits: 2,
        rounding: 0,
        code: 'SDG',
        name_plural: 'Sudanese pounds',
      },
      {
        symbol: 'Skr',
        name: 'Swedish Krona',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'SEK',
        name_plural: 'Swedish kronor',
      },
      {
        symbol: 'S$',
        name: 'Singapore Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'SGD',
        name_plural: 'Singapore dollars',
      },
      {
        symbol: 'Ssh',
        name: 'Somali Shilling',
        symbol_native: 'Ssh',
        decimal_digits: 0,
        rounding: 0,
        code: 'SOS',
        name_plural: 'Somali shillings',
      },
      {
        symbol: 'SY£',
        name: 'Syrian Pound',
        symbol_native: 'ل.س.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'SYP',
        name_plural: 'Syrian pounds',
      },
      {
        symbol: '฿',
        name: 'Thai Baht',
        symbol_native: '฿',
        decimal_digits: 2,
        rounding: 0,
        code: 'THB',
        name_plural: 'Thai baht',
      },
      {
        symbol: 'DT',
        name: 'Tunisian Dinar',
        symbol_native: 'د.ت.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'TND',
        name_plural: 'Tunisian dinars',
      },
      {
        symbol: 'T$',
        name: 'Tongan Paʻanga',
        symbol_native: 'T$',
        decimal_digits: 2,
        rounding: 0,
        code: 'TOP',
        name_plural: 'Tongan paʻanga',
      },
      {
        symbol: 'TL',
        name: 'Turkish Lira',
        symbol_native: 'TL',
        decimal_digits: 2,
        rounding: 0,
        code: 'TRY',
        name_plural: 'Turkish Lira',
      },
      {
        symbol: 'TT$',
        name: 'Trinidad and Tobago Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'TTD',
        name_plural: 'Trinidad and Tobago dollars',
      },
      {
        symbol: 'NT$',
        name: 'New Taiwan Dollar',
        symbol_native: 'NT$',
        decimal_digits: 2,
        rounding: 0,
        code: 'TWD',
        name_plural: 'New Taiwan dollars',
      },
      {
        symbol: 'TSh',
        name: 'Tanzanian Shilling',
        symbol_native: 'TSh',
        decimal_digits: 0,
        rounding: 0,
        code: 'TZS',
        name_plural: 'Tanzanian shillings',
      },
      {
        symbol: '₴',
        name: 'Ukrainian Hryvnia',
        symbol_native: '₴',
        decimal_digits: 2,
        rounding: 0,
        code: 'UAH',
        name_plural: 'Ukrainian hryvnias',
      },
      {
        symbol: 'USh',
        name: 'Ugandan Shilling',
        symbol_native: 'USh',
        decimal_digits: 0,
        rounding: 0,
        code: 'UGX',
        name_plural: 'Ugandan shillings',
      },
      {
        symbol: '$U',
        name: 'Uruguayan Peso',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'UYU',
        name_plural: 'Uruguayan pesos',
      },
      {
        symbol: 'UZS',
        name: 'Uzbekistan Som',
        symbol_native: 'UZS',
        decimal_digits: 0,
        rounding: 0,
        code: 'UZS',
        name_plural: 'Uzbekistan som',
      },
      {
        symbol: 'Bs.F.',
        name: 'Venezuelan Bolívar',
        symbol_native: 'Bs.F.',
        decimal_digits: 2,
        rounding: 0,
        code: 'VEF',
        name_plural: 'Venezuelan bolívars',
      },
      {
        symbol: '₫',
        name: 'Vietnamese Dong',
        symbol_native: '₫',
        decimal_digits: 0,
        rounding: 0,
        code: 'VND',
        name_plural: 'Vietnamese dong',
      },
      {
        symbol: 'FCFA',
        name: 'CFA Franc BEAC',
        symbol_native: 'FCFA',
        decimal_digits: 0,
        rounding: 0,
        code: 'XAF',
        name_plural: 'CFA francs BEAC',
      },
      {
        symbol: 'CFA',
        name: 'CFA Franc BCEAO',
        symbol_native: 'CFA',
        decimal_digits: 0,
        rounding: 0,
        code: 'XOF',
        name_plural: 'CFA francs BCEAO',
      },
      {
        symbol: 'YR',
        name: 'Yemeni Rial',
        symbol_native: 'ر.ي.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'YER',
        name_plural: 'Yemeni rials',
      },
      {
        symbol: 'R',
        name: 'South African Rand',
        symbol_native: 'R',
        decimal_digits: 2,
        rounding: 0,
        code: 'ZAR',
        name_plural: 'South African rand',
      },
      {
        symbol: 'ZK',
        name: 'Zambian Kwacha',
        symbol_native: 'ZK',
        decimal_digits: 0,
        rounding: 0,
        code: 'ZMK',
        name_plural: 'Zambian kwachas',
      },
    ],
  },
];

export const WEIGHT_UNITS = [
  {
    name: 'gram',
    label: 'g',
    value: 'g',
  },
  {
    name: 'kilogram',
    label: 'Kg',
    value: 'Kg',
  },
  {
    name: 'ounce',
    label: 'oz',
    value: 'oz',
  },
  {
    name: 'pound',
    label: 'lb',
    value: 'lb',
  },
];

export const PALLET_WEIGHT_UNITS = [
  {
    name: 'kilogram',
    label: 'Kg',
    value: 'Kg',
  },
  {
    name: 'pound',
    label: 'lb',
    value: 'lb',
  },
];

export const VOLUME_UNIT_GROUPS = [
  {
    groupName: 'Popular',
    volumeUnits: [
      {
        name: 'litre',
        label: 'l',
        value: 'l',
      },
      {
        name: 'millilitre',
        label: 'ml',
        value: 'ml',
      },
      {
        name: 'kilogram',
        label: 'Kg',
        value: 'Kg',
      },
      {
        name: 'cubic centimetre',
        label: 'cm3',
        value: 'cm3',
      },
    ],
  },
  {
    groupName: 'More volume units',
    volumeUnits: [
      {
        name: 'cubic millimetre',
        label: 'mm3',
        value: 'mm3',
      },
      {
        name: 'cubic decimetre',
        label: 'dm3',
        value: 'dm3',
      },
      {
        name: 'cubic metre',
        label: 'm3',
        value: 'm3',
      },
    ],
  },
];

export const DIMENSION_UNITS = [
  {
    name: 'millimetre',
    label: 'mm',
    value: 'mm',
  },
  {
    name: 'centimetre',
    label: 'cm',
    value: 'cm',
  },
  {
    name: 'decimetre',
    label: 'dm',
    value: 'dm',
  },
  {
    name: 'metre',
    label: 'm',
    value: 'm',
  },
];

export const QUANTITY_UNITS = [
  {
    name: 'pallet',
    label: 'Pallet',
    value: 'pallet',
  },
  {
    name: 'crate',
    label: 'Crate',
    value: 'crate',
  },
  {
    name: 'carton',
    label: 'Carton',
    value: 'carton',
  },
];

export const SKU_NAMES = [
  {
    label: 'Item',
    value: 'Item',
  },
  {
    label: 'Case',
    value: 'Case',
  },
  {
    label: 'Pallet',
    value: 'Pallet',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const PALLET_TYPES = [
  {
    label: 'GB Pallet - 1.2m x 1m (L x W)',
    value: 'GB Pallet',
  },
  {
    label: 'GB Half Pallet - 1m x 0.6m (L x W)',
    value: 'GB Half Pallet',
  },
  {
    label: 'EU Pallet - 1.2m x 0.8m (L x W)',
    value: 'EU Pallet',
  },
  {
    label: 'EU Half Pallet - 0.6m x 0.8m (L x W)',
    value: 'EU Half Pallet',
  },
  {
    label: 'Custom Pallet',
    value: 'Custom Pallet',
  },
];

export const STANDARD_PALLET_HEIGHT_IN_METRES = 0.15;

export const SHIPMENT_NOTE_STATUSES: Status[] = [
  'All',
  'Saved',
  'Created',
  'Delivered',
  'Cancelled',
];

export const SHIPMENT_NOTE_TYPES: ShipmentType[] = ['Inbound', 'Outbound'];

export const SHIPPER_QUESTION_SET = [
  {
    id: 'servicesNeeded',
    questionText: 'Please describe the services you are looking for *',
    type: 'radio',
    options: [
      'Leasable space',
      // 'Storage space and transportation',
      'Pallet storage',
      // 'Pallet storage and transportation',
      // 'Transportation only',
    ],
    page: 1,
    mandatory: true,
  },
  {
    id: 'storageSpaceOrMinPalletsNeeded',
    questionText: 'Space/pallet storage needed (in sq.m/pallets) *',
    type: 'text',
    page: 1,
    mandatory: true,
  },
  {
    id: 'location',
    questionText: 'Desired Location/Region *',
    type: 'map',
    page: 1,
    mandatory: true,
  },
  {
    id: 'email',
    questionText: 'Email address *',
    type: 'text',
    page: 1,
    mandatory: true,
  },
  {
    id: 'firstname',
    questionText: 'First name *',
    type: 'text',
    page: 1,
    mandatory: true,
  },
  {
    id: 'lastname',
    questionText: 'Last name *',
    type: 'text',
    page: 1,
    mandatory: true,
  },
  // {
  //   id: 'intendedStartDate',
  //   questionText: 'Intended Start Date *',
  //   type: 'date',
  //   page: 2,
  //   mandatory: true,
  // },
  // {
  //   id: 'minimalTerm',
  //   questionText: 'Expected duration (in months) *',
  //   type: 'text',
  //   page: 2,
  //   mandatory: true,
  // },
  // {
  //   id: 'transportationNeeded',
  //   questionText: '# of inbound shipments a week *',
  //   type: 'text',
  //   page: 2,
  //   mandatory: true,
  // },
  // {
  //   id: 'averageShipmentType',
  //   questionText: 'Outbound transportation requirements *',
  //   type: 'radio',
  //   options: ['Parcels', 'Pallets', 'Other'],
  //   page: 2,
  //   mandatory: true,
  // },
  // {
  //   id: 'averageShipmentSize',
  //   questionText: '# of outbound shipments per week *',
  //   type: 'text',
  //   page: 2,
  //   mandatory: true,
  // },
  // {
  //   id: 'needed',
  //   questionText: 'Services needed *',
  //   type: 'checkbox',
  //   options: [
  //     'Leasable Space',
  //     'Managed Services',
  //     'Haulage&Distribution',
  //     'Enhanced Security',
  //     'ValueAdded Services',
  //     'Additional Accreditations',
  //   ],
  //   page: 3,
  //   mandatory: true,
  // },
  // {
  //   id: 'additionalCapabilities',
  //   questionText: 'Capabilities required',
  //   type: 'checkbox',
  //   options: [
  //     'Temperature Controlled',
  //     'Special Handling',
  //     'E-commerce and fulfilment',
  //     'Returns',
  //     'Co-packing',
  //     'IS enabled',
  //   ],
  //   page: 3,
  //   mandatory: false,
  // },
  // {
  //   id: 'typeOfBusiness',
  //   questionText: 'Please describe your business *',
  //   type: 'radio',
  //   options: ['Consumer Goods', 'Specialty or Industrial', 'Other'],
  //   page: 4,
  //   mandatory: true,
  // },
  // {
  //   id: 'sizeOfBusiness',
  //   questionText: 'Annual turnover *',
  //   type: 'radio',
  //   options: [
  //     'Micro (<£5M)',
  //     'SME (<50M)',
  //     'Large (<£500M)',
  //     'Multinational (>£500M)',
  //   ],
  //   page: 4,
  //   mandatory: true,
  // },
  // {
  //   id: 'supplyChainTeam',
  //   questionText: 'Your Logistics & Supply Chain team *',
  //   type: 'radio',
  //   options: ['1', '2-3', '4-10', '10-25', '>25'],
  //   page: 4,
  //   mandatory: true,
  // },
];

export const LSP_QUESTION_SET = [
  {
    id: 'typeOfBusiness',
    questionText: 'Please describe your business *',
    type: 'radio',
    options: [
      '3PL/4PL',
      'Warehouse / Fulfilment Service Provider',
      'Transport Service Provider',
      'Other',
    ],
    page: 1,
    mandatory: true,
  },
  // {
  //   id: 'sizeOfBusiness',
  //   questionText: 'Annual turnover *',
  //   type: 'radio',
  //   options: [
  //     'Micro (<£5M)',
  //     'SME (<50M)',
  //     'Large (<£500M)',
  //     'Multinational (>£500M)',
  //   ],
  //   page: 1,
  //   mandatory: true,
  // },
  {
    id: 'email',
    questionText: 'Email address *',
    type: 'text',
    page: 1,
    mandatory: true,
  },
  {
    id: 'firstname',
    questionText: 'First name *',
    type: 'text',
    page: 1,
    mandatory: true,
  },
  {
    id: 'lastname',
    questionText: 'Last name *',
    type: 'text',
    page: 1,
    mandatory: true,
  },
  // {
  //   id: 'maxStorageCapacityMeters',
  //   questionText: 'Max capacity, in sq.m *',
  //   type: 'text',
  //   page: 2,
  //   mandatory: true,
  // },
  // {
  //   id: 'maxStorageCapacityPallets',
  //   questionText: 'Max capacity, in pallets *',
  //   type: 'text',
  //   page: 2,
  //   mandatory: true,
  // },
  {
    id: 'availableStorageCapacityMeters',
    questionText: 'Available capacity, in sq.m *',
    type: 'text',
    page: 2,
    mandatory: true,
  },
  {
    id: 'availableStorageCapacityPallets',
    questionText: 'Available capacity, in pallets *',
    type: 'text',
    page: 2,
    mandatory: true,
  },
  {
    id: 'minServiceTermMonths',
    questionText: 'Minimum service term in months *',
    type: 'text',
    page: 2,
    mandatory: true,
  },
  {
    id: 'minServiceCapacity',
    questionText: 'Minimum service capacity, in sq.m or pallets *',
    type: 'text',
    page: 2,
    mandatory: true,
  },
  // {
  //   id: 'needed',
  //   questionText: '',
  //   type: 'checkbox',
  //   options: [
  //     'Leasable Space',
  //     'Managed Services/Fulfilment',
  //     'Haulage&Distribution',
  //     'Enhanced Security',
  //     'ValueAdded Services',
  //     'Additional Accreditations',
  //   ],
  //   page: 2,
  //   mandatory: true,
  // },
  {
    id: 'ownFleet',
    questionText: 'Transportation *',
    type: 'radio',
    options: ['Own fleet', 'Subcontracted'],
    page: 2,
    mandatory: true,
  },
  {
    id: 'temperatureControlled',
    questionText: 'Temperature controls *',
    type: 'radio',
    options: ['Ambient', 'Chilled', 'Frozen', 'Mixed'],
    page: 3,
    mandatory: true,
  },
  // {
  //   id: 'warehouse',
  //   questionText: 'Warehouse capabilites',
  //   type: 'checkbox',
  //   options: [
  //     'Racking',
  //     'High-Bay',
  //     'Hazardous',
  //     'Levelled Docks',
  //     'Picking/Labelling',
  //   ],
  //   page: 3,
  //   mandatory: true,
  // },
  // {
  //   id: 'distribution',
  //   questionText: 'Additional service capabilities',
  //   type: 'checkbox',
  //   options: [
  //     'LTL loads',
  //     'E-fulfilment',
  //     'Cross-docking',
  //     'Hazardous',
  //     'Electric vehicles',
  //   ],
  //   page: 3,
  //   mandatory: true,
  // },
  {
    id: 'targetCustomerType',
    questionText: 'Desired customer type *',
    type: 'radio',
    options: ['Consumer Goods', 'Specialty or Industrial', 'Other'],
    page: 3,
    mandatory: true,
  },
];

export const WarehouseTandCsText = `(the “Service Provider”) is not a common carrier and undertakes all services subject solely to
the following Conditions which can be varied only in writing by a Director, Company Secretary or Partner of the
Service Provider.
If a Service Recipient’s acceptance document, purchase order or other documentation, received by the Service
Provider before or after notification of these Conditions, contains terms or conditions additional to, or at
variance with these Conditions, then every such additional or varying term or condition shall be of no effect.
IMPORTANT NOTE
THE SERVICE RECIPIENT’S ATTENTION IS DRAWN SPECIFICALLY TO CONDITION 3. Condition 3 has been included
herein solely to relieve the owner of the goods (including any associated packing and equipment) the subject of
this contract (the ”Goods”), or the owner’s agent, of the additional costs that the Service Provider would need to
include to recover insurance charges were its liability not limited as provided for in Condition 3.
Condition 3(e)(ii) will become operative at the option of the Service Recipient on the terms provided therein.
1) WARRANTY OF AGENCY
The Service Recipient warrants that it is either the owner of the Goods or is authorised by such owner to
accept these Conditions on the owner’s behalf.
2) SERVICE RECIPIENT’S UNDERTAKINGS
a) The Service Recipient undertakes that:

i) When presented for warehousing, the Goods shall be securely and properly packed in compliance
with any statutory regulations or official or recognised standards and in such condition as not to cause
damage or injury or the likelihood of damage or injury to the property of the Service Provider or to
any other goods, whether by spreading of damp, infestation, leakage or the escape of fumes or
substances or otherwise howsoever.
ii) Before presentation of the Goods for warehousing, the Service Recipient will inform the Service
Provider in writing of any special precautions necessitated by the nature, weight or condition of the
Goods and of any statutory duties specific to the Goods with which the Service Provider may need to
comply.
iii) It will reimburse all duties and taxes that the Service Provider may be required to pay in respect of the
Goods including situations where the liability to pay them arises due to the fault of the Service
Provider or its employees or sub-contractors.
iv) Unless prior to acceptance of the Goods by the Service Provider, the Service Provider receives written
notice containing all appropriate information, none of the Goods constitute “Waste” as defined in the
Environmental Protection Act 1990.
v) Unless prior to acceptance of the Goods by the Service Provider, the Service Provider receives written
notice containing all appropriate information, none of the Goods are or contain substances the
storage of which would require the obtaining of any consent or licence or which, if they escaped from
their packaging, would or may cause pollution of the environment or harm to human health.

vi) It will be liable for any breach of HM Customs & Excise Regulations relating to goods warehoused and
undertakes to indemnify and keep indemnified the Service Provider against all actions, proceedings,
costs, claims and demands arising out of any further breach, non-observance or non-performance of
the same.
b) Notwithstanding any notice under Condition 3(e)(ii), if there is a breach of contract by the Service
Recipient, the Service Recipient will indemnify the Service Provider against any loss or damage it suffers
which is related to the breach, and will pay all costs and expenses (including professional fees) incurred in,
and the Service Provider’s reasonable charges for, dealing with the breach and its consequences. The
Service Recipient will pay an extra storage charge equal to the amount of any fine or penalty payable by
the Service Provider wholly or partly as a result of a breach by the Service Recipient of this contract. If the
Service Provider suspects a breach of warranty in Condition 1 or of any undertaking in Condition 2(a), it
may demand the immediate removal of any goods held for the Service Recipient, or itself arrange their
removal without notice, at the Service Recipient’s expense.
3) COMPANY’S LIABILITY FOR GOODS AND OTHER LOSSES
a) Except as provided in Condition 3(c) below, the Service Provider does not insure the Goods and the
Service Recipient shall make arrangements to cover the Goods against all risks to the full insurable value
thereof (including all duties and taxes).
b) Subject only to sub-clause 3(c) below, the Service Provider excludes all liability whatsoever and howsoever
arising in respect of the Goods including (without limitation) all liability for loss, damage, duties and taxes,
deterioration, delay, non-delivery, mis-delivery, unauthorised delivery or non- compliance with
instructions (“Loss”).
c) If and to the extent that Loss is directly caused by neglect or wilful act or default of the Service Provider,
its employees (acting in furtherance of their duties as employees) or sub-contractors (acting in
furtherance of their duties as sub-contractors) and subject to sub-clause 3(f) below, the Service Provider
will accept liability for Loss up to the Limit fixed by sub-clause 3(e) below.
d) In no case whatsoever (including without limitation a case within sub-clause 3(c) above) shall the Service
Provider be liable for any loss of profit or income or indirect or consequential Loss of any kind.
e) In no case whatsoever (including inter alia a case within sub-clause 3(c) above) shall any liability of the
Service Provider (including inter alia any liability in respect of duties and taxes) exceed a limit per tonne
weight of that part of the Goods in respect of which a claim arises (“the Limit”) fixed as follows:
i)

If a higher Limit has not been implemented under (ii) below, the Limit shall be a total of £100 per
tonne.

ii) The Service Recipient may require an increase in the per tonne limit under (i) by notice in writing to be
received by the Service Provider at least 7 days before the date (“the Date”) on which the increased
Limit is required to be operative. The notice shall state the increased Limit and the nature and
maximum value of the Goods, including duty and taxes. The increased Limit shall apply in respect of
any cause of action arising after the Date. The Service Provider shall accept such increase subject to
payment by the Service Recipient within 7 days of invoice of the Service Provider’s costs in insuring
against its increased liability under the increased Limit.
f)

Without prejudice to the Service Provider’s rights under clause 6 to be paid free from deductions, any
limitation of liability on the part of the Service Provider shall be applied to any claim by the Service
Recipient before any set off or counterclaim is asserted against money due to the Service Provider.

g) The Service Provider:
i)

shall not be liable for any Claim unless it has received written notice of the Claim from the Service
Recipient within 21 days (7 days in the case of sub-contract carriage) of the cause of the Claim coming
to the Service Recipient’s knowledge or of the Goods being delivered by the Service Provider to or to
the use of the Service Recipient, whichever is the later.

ii) No legal proceedings may be brought against the Service Provider unless they are issued and served,
and no counterclaim may be raised unless full written details are received by the Service Provider,
within 9 months of the event giving rise to the Claim.
h) The Service Provider shall not be liable hereunder for any loss or damage to the extent that the same is
caused or contributed to by a breach of any of the Service Recipient’s warranties and undertakings (or by
any of the circumstances by virtue of which the Service Provider is relieved of its contractual obligations in
accordance with Condition 8).
4) EMPLOYEES AND SUB-CONTRACTORS
a) The Service Recipient and the Owner of the Goods will not take any proceedings against any employee or
sub-contractor of the Service Provider for a Claim.
b) Without prejudice to Condition 4(a), if an employee or sub-contractor pays or is liable to make a payment
to the Service Recipient or Owner of the Goods in connection with a Claim, the Service Recipient and the
Owner of the Goods will each fully indemnify the Service Provider against any claim (including all costs
and expenses) by the employee or sub-contractor against the Service Provider for reimbursement of or
indemnity against that payment to the extent that it exceeds £100 per tonne weight of that part of the
Goods the subject of a Claim or any higher figure agreed under Condition 3(e)(ii).
c) In any of the circumstances referred to in Condition 4(d) hereof, and otherwise with the written consent
of the Service Recipient, the Service Provider shall be entitled to sub-contract all or any part of its business
and in this event these Conditions shall apply to such services. The Service Provider shall be entitled to
sub-contract with others for the security, cleaning, maintenance, repair and other services and works at
the premises where the Goods are located.
d) The circumstances referred to in Condition 4(c) hereof are actual or anticipated storm, flood, fire,
explosion, breakdown or failure of plant and/or machinery, riot, civil disturbance, industrial dispute,
labour disturbance, requirement of a responsible Authority or any emergency reasonably requiring such
action by the Service Provider.
5) CHANGE OF SERVICE RECIPIENT
a) The Service Recipient may give written authority for the Goods or any part thereof to be transferred by
the Service Provider to the account of another party but subject to the Service Recipient ensuring before
the effective date of the transfer that such other party notifies the Service Provider in writing that it is to
become the Service Recipient and is to be bound by these Conditions and by any notice given under
Condition 3. Further, the Service Recipient agrees to continue to pay the Service Provider’s charges until
receipt and acceptance by the Service Provider of the other party’s written notification.
6) CHARGES, PAYMENTS AND LIEN
a) The Service Provider’s charges, which may be increased from time to time by at least 21 days’ prior notice
to the Service Recipient, shall be payable free of any deductions at such periodic intervals as may have
been agreed between the parties and in any event on the earlier of (a) the expiry of any agreed period of

credit and (b) the time immediately before the removal of the Goods from the Service Provider’s custody
or control. Interest on amounts due and unpaid shall be payable from the date when payment of such
amounts fell due and shall be calculated at the rate of 2% (per cent) for each calendar month during all or
part of which a payment is overdue. Further, the Service Provider shall have on the Goods a particular lien,
as well as a general lien entitling it to retain the Goods as security for payment of all sums claimed by the
Service Provider from the Service Recipient or the Owner of the Goods on any account (relating to the
Goods or not). Storage charges shall continue to accrue on any goods detained under lien.
7) TERMINATION
a) The Goods shall be removed by the Service Recipient from the custody or control of the Service Provider
at such date as may have been agreed between the parties. In the absence of such agreement, and
otherwise where reasonably necessary, the Service Provider may at any time by notice in writing to the
Service Recipient require the removal of the Goods within 28 days from the date of such notice or, in the
case of perishable goods, within 3 days.
b) In the event of failure by the Service Recipient by the due time to remove any of the Goods from the
custody or control of the Service Provider (notice in accordance with Condition 7(a) having been given) or
to pay any amount claimed by the Service Provider, the Service Provider may, without prejudice to its
other rights and remedies against the Service Recipient, give notice in writing to the Service Recipient of
the Service Provider’s intention to sell or otherwise dispose of the Goods at the Service Recipient’s entire
risk and expense if such amount is not paid and/or such Goods are not removed within 28 days, or in the
case of perishable goods within 3 days from the date of such notice. On the expiry of such period, if such
payment has not been made and/or the Goods have not been so removed the Service Provider shall be
entitled to sell or otherwise dispose of all or any part of the Goods at the Service Recipient’s entire risk
and expense and the proceeds of any sale or disposal shall be remitted to the Service Recipient after
deduction therefrom of all expenses and all amounts claimed by the Service Provider from the Service
Recipient or the Goods owner on any account. The Service Provider will use a reasonably appropriate
method of sale but will not be liable for any inadequacy in the price achieved.
c) In the case of perishable goods, notice under Condition 7(ii) may be combined with a notice under
Condition 7(a).
8) FRUSTRATION OF CONTRACT
a) The Service Provider shall be relieved of its contractual obligations to the extent that their performance is
prevented by, or their non-performance results wholly or partly, directly or indirectly from the act,
neglect, or default of the Service Recipient, including any breach by the Service Recipient of these
Conditions, or by storm, flood, fire, explosion, breakdown or unavailability of plant and/or machinery, riot,
civil disturbance, industrial dispute, labour disturbance or cause beyond the reasonable control of the
Service Provider.
9) GENERAL
a) Each exclusion or limitation in these Conditions exists separately and cumulatively.
b) When reasonably necessary and at the discretion of the Service Provider the Goods may be carried, stored
or handled with other compatible goods or transferred between stores.
c) Any notice or statement of account given by the Service Provider to the Service Recipient shall be duly
given if left at or sent by first class post to the last known address of the Service Recipient or by facsimile
to the last notified number or by e-mail to the last notified e-mail address and such notice or account shall

if posted be deemed to have been given 2 working days after posting and, if by facsimile or e-mail (in the
case of e-mail, with delivery reported), the next working day.
10) GOVERNING LAW
a) All contracts between the Service Provider and the Service Recipient shall be governed in all respects by
the law of England (unless the goods are stored in Scotland in which case Scottish law applies) and the
Service Recipient hereby submits to the exclusive jurisdiction of the English (or Scottish if appropriate)
courts.
11) OTHER CONDITIONS OF BUSINESS
a) If the business undertaken comprises or includes any of the following activities, then these Conditions
shall still apply to the activity except to the extent that they are inconsistent with the Service Provider’s
own standard terms (if any) for such activity in which case those standard terms shall apply (but these
Conditions will always apply in connection with the loading or unloading of the Goods, or the transfer of
the Goods as referred to in Condition 9(b)).
i)

Carriage of goods

ii) Vehicle repair and maintenance
iii) Freight Forwarding.

`;
