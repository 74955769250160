/* eslint-disable no-nested-ternary */
import Stack from '@mui/material/Stack';
import { Text } from '@components/Text';
import { OutboundProcess } from '@features/supplyChain/types';
import { useReactHookFormWithFieldArray } from '@hooks';
import dayjs from 'dayjs';
import { Box, Chip, TextField } from '@mui/material';
import { ChangeEvent, Fragment } from 'react';
import { Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { DateTimePicker } from '@mui/x-date-pickers';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import { useAuth } from '@context';
import { PalletCalculator } from '@components/PalletCalculator';
import { CreateProofOfDeliveryFormValues } from './types';
import { makeDefaultValues, validationSchema } from './form';

function QuantitySpacer() {
  return <span>&nbsp;</span>;
}

interface CreateProofOfDeliveryFormProps {
  selectedOutboundProcess: OutboundProcess;
  onClose: () => void;
  onSubmit: (values: CreateProofOfDeliveryFormValues) => void;
  isDesktop: boolean;
  isTablet: boolean;
}

export function CreateProofOfDeliveryForm({
  selectedOutboundProcess,
  onClose,
  onSubmit,
  isDesktop,
  isTablet,
}: CreateProofOfDeliveryFormProps) {
  const {
    handleSubmit,
    control,
    setValue,
    isSubmitting,
    isValid,
    fields: skusFields,
    watch,
  } = useReactHookFormWithFieldArray<CreateProofOfDeliveryFormValues>({
    defaultValues: makeDefaultValues(selectedOutboundProcess),
    validationSchema,
    fieldArrayName: 'skus',
  });

  const { userBusinessName } = selectedOutboundProcess;
  const { estimatedTimeOfArrival, shipmentNoteNumber, customerName } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    selectedOutboundProcess.shipmentNote!;

  const { user } = useAuth();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={16}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Proof of Delivery #
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {shipmentNoteNumber.replace('-SN', '-POD')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Recipient name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{customerName}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Recipient address
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>123 Main St</Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Signed off by
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{user?.name}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Required ETA
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {`${dayjs(
                selectedOutboundProcess.despatchNote?.requiredTimeOfArrival
              ).format('DD/MM/YYYY')}, by ${dayjs(
                selectedOutboundProcess.despatchNote?.requiredTimeOfArrival
              ).format('HH:mm')}`}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Actual Delivery Date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              <Controller
                name='actualDeliveryDate'
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <DateTimePicker
                      ampm={false}
                      format='DD/MM/YYYY H:mm'
                      minDate={dayjs(new Date())}
                      inputRef={field.ref}
                      onChange={(value) => {
                        setValue('actualDeliveryDate', dayjs(value).format(), {
                          shouldValidate: true,
                        });
                      }}
                      defaultValue={dayjs()}
                      slotProps={{
                        textField: {
                          onBlur: field.onBlur,
                          name: field.name,
                          multiline: !isDesktop,
                          ...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          }),
                          error: Boolean(error),
                          helperText: error?.message,
                        },
                      }}
                    />
                  );
                }}
              />
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        {isDesktop && (
          <>
            <Box
              display='grid'
              gridTemplateColumns='2rem repeat(12, 1fr)'
              columnGap='1rem'
              rowGap='1rem'
              alignItems='center'
            >
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '1 / 2', textAlign: 'center' }}
              >
                #
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '2 / 4' }}
              >
                SKU ID
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '4 / 6' }}
              >
                Product name
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '6 / 8' }}
              >
                UoM
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '8 / 10' }}
              >
                Despatched <br />
                SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '10 / 12' }}
              >
                Delivered <br />
                SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '12 / 14' }}
              >
                Delivered <br />
                Pallet Q-ty
              </Text>
            </Box>
            <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
          </>
        )}
        {skusFields.map((skusField, index) => {
          const despatchedQuantity = watch(`skus.${index}.despatchedQuantity`);
          const receivedSkuQuantity = watch(
            `skus.${index}.receivedSkuQuantity`
          );

          const isDiscrepancy =
            receivedSkuQuantity !== undefined &&
            despatchedQuantity !== receivedSkuQuantity;

          const hasPackagingInfo = skusField.packagingDetails.packagingInfo;

          let amountOfSkusInLayer: number | undefined;
          let amountOfLayersInPallet: number | undefined;
          let packagingInfoType: string | undefined;
          let palletisingType: string | null | undefined;

          if (hasPackagingInfo) {
            amountOfSkusInLayer =
              skusField.packagingDetails.packagingInfo?.amountOfSkusInLayer;

            amountOfLayersInPallet =
              skusField.packagingDetails.packagingInfo?.amountOfLayersInPallet;

            packagingInfoType = skusField.packagingDetails.packagingInfo?.type;
          } else {
            palletisingType = skusField.packagingDetails.palletisingType;
          }

          return (
            <Fragment key={skusField.id}>
              <Box
                display='grid'
                gridTemplateColumns='2rem repeat(12, 1fr)'
                gridTemplateRows={
                  isDesktop
                    ? `repeat(${isDiscrepancy ? '2' : '1'}, min-content)`
                    : isDiscrepancy
                    ? 'repeat(3, min-content)'
                    : 'repeat(2, min-content)'
                }
                columnGap='1rem'
                rowGap='1rem'
              >
                {/* SKU index  */}
                <Stack gridColumn='1 / 2' sx={{ alignSelf: 'center' }}>
                  <Chip label={index + 1} />
                </Stack>
                {/* SKU ID */}
                <Stack
                  gridColumn={
                    isDesktop ? '2 / 4' : isTablet ? '2 / 8' : '2 / 14'
                  }
                  gridRow={isDesktop ? '1' : isTablet ? '1' : '1'}
                  spacing={8}
                  sx={{ alignSelf: 'center' }}
                >
                  {!isDesktop && (
                    <Text size='sm' weight='bold'>
                      SKU ID
                    </Text>
                  )}
                  <Text>{skusField.skuIdentifier}</Text>
                </Stack>
                {/* Product name */}
                <Stack
                  gridColumn={
                    isDesktop ? '4 / 6' : isTablet ? '2 / 8' : '2 / 14'
                  }
                  gridRow={isDesktop ? '1' : isTablet ? '2' : '2'}
                  spacing={8}
                  sx={{ alignSelf: 'center' }}
                >
                  {!isDesktop && (
                    <Text size='sm' weight='bold'>
                      Product name
                    </Text>
                  )}
                  <Text>{skusField.productName}</Text>
                </Stack>
                {/* UoM */}
                <Stack
                  gridColumn={
                    isDesktop ? '6 / 8' : isTablet ? '2 / 8' : '2 / 14'
                  }
                  gridRow={isDesktop ? '1' : isTablet ? '3' : '3'}
                  spacing={8}
                  sx={{ alignSelf: 'center' }}
                >
                  {!isDesktop && (
                    <Text size='sm' weight='bold'>
                      UoM
                    </Text>
                  )}
                  <Text>{skusField.skuUnitOfMeasure}</Text>
                </Stack>
                {/* Despatched SKU quantity */}
                <Stack
                  gridColumn={
                    isDesktop ? '8 / 10' : isTablet ? 'span 6' : '2 / 14'
                  }
                  gridRow={isDesktop ? '1' : isTablet ? '1' : '4'}
                  spacing={8}
                  sx={{ alignSelf: 'center' }}
                >
                  {!isDesktop && (
                    <Text size='sm' weight='bold'>
                      Despatched SKU Q-ty
                    </Text>
                  )}
                  <Text>{skusField.despatchedQuantity}</Text>
                </Stack>
                {/* Delivered SKU quantity */}
                <Box
                  gridColumn={
                    isDesktop ? '10 / 12' : isTablet ? '8 / 14' : '2 / 14'
                  }
                  gridRow={isDesktop ? '1' : isTablet ? '2' : '5'}
                  sx={{ alignSelf: 'center' }}
                >
                  <Controller
                    name={`skus.${index}.receivedSkuQuantity`}
                    control={control}
                    render={({ field, fieldState: { error, isTouched } }) => (
                      <TextField
                        onBlur={field.onBlur}
                        name={field.name}
                        value={field.value}
                        ref={field.ref}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          const receivedSkuQuantityValue = parseInt(
                            e.target.value,
                            10
                          );

                          setValue(
                            `skus.${index}.receivedSkuQuantity`,
                            receivedSkuQuantityValue,
                            {
                              shouldValidate: true,
                            }
                          );

                          if (receivedSkuQuantityValue === despatchedQuantity) {
                            setValue(`skus.${index}.discrepancyReason`, '');
                          }
                        }}
                        variant='outlined'
                        size='small'
                        error={Boolean(error)}
                        helperText={Boolean(error) && error?.message}
                        color='primary'
                        label='Delivered SKU Q-ty (required)'
                        placeholder='Delivered SKU Q-ty (required)'
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          // sx: {
                          //   fontSize: 'var(--fontSizes-textLg)',
                          //   fontWeight: 'var(--fontWeights-bold)',
                          //   lineHeight: 'var(--lineHeights-textSm)',
                          //   color: 'var(--colors-gray12)',
                          //   '&.Mui-focused, &.Mui-error': {
                          //     color: 'var(--colors-gray12)',
                          //   },
                          // },
                        }}
                        inputProps={{
                          sx: {
                            color: 'primary',
                          },
                          type: 'number',
                          min: 0,
                        }}
                      />
                    )}
                  />
                </Box>
                {/* Delivered Pallets Q-ty */}
                {hasPackagingInfo ? (
                  <Stack
                    gridColumn={
                      isDesktop ? '12 / 14' : isTablet ? '8 / 14' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '3' : '6'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        Delivered Pallet Q-ty
                      </Text>
                    )}
                    <Text css={{ whiteSpace: 'nowrap' }}>
                      {receivedSkuQuantity ? (
                        <PalletCalculator
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          palletName={packagingInfoType!}
                          skuQuantity={receivedSkuQuantity}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          amountOfSkusInLayer={amountOfSkusInLayer!}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          amountOfLayersInPallet={amountOfLayersInPallet!}
                        />
                      ) : (
                        <QuantitySpacer />
                      )}
                    </Text>
                  </Stack>
                ) : (
                  <Stack
                    gridColumn={
                      isDesktop ? '12 / 14' : isTablet ? '8 / 14' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '3' : '6'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        Delivered Pallet Q-ty
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {palletisingType}
                    </Text>
                  </Stack>
                )}
                {/* Discrepancy reason */}
                {isDiscrepancy && (
                  <Box
                    gridColumn={
                      isDesktop ? '10 / 14' : isTablet ? '8 / 14' : '2 / 14'
                    }
                    gridRow={isDesktop ? '2' : isTablet ? '4' : '7'}
                  >
                    <Controller
                      name={`skus.${index}.discrepancyReason`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => {
                        return (
                          <TextField
                            size='small'
                            name={field.name}
                            onBlur={field.onBlur}
                            inputRef={field.ref}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              field.onChange(event.target.value);
                            }}
                            multiline
                            minRows={2}
                            error={Boolean(error && isTouched)}
                            helperText={
                              Boolean(error && isTouched) && error?.message
                            }
                            label='Discrepancy reason (required)'
                            fullWidth
                            placeholder='Discrepancy reason (required)'
                            {...(!isDesktop && {
                              InputLabelProps: {
                                shrink: true,
                              },
                            })}
                            variant='outlined'
                            sx={{
                              '.Mui-disabled': { cursor: 'not-allowed' },
                            }}
                          />
                        );
                      }}
                    />
                  </Box>
                )}
              </Box>
              {index !== skusFields.length - 1 && (
                <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
              )}
            </Fragment>
          );
        })}
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          Comment
        </Text>
        <Controller
          name='comment'
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              {...field}
              multiline
              minRows={4}
              error={Boolean(error && isTouched)}
              helperText={Boolean(error && isTouched) && error?.message}
              label='Comment (optional)'
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
              variant='outlined'
            />
          )}
        />
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={onClose}
        >
          Discard
        </Button>
        <LoadingButton
          type='submit'
          size='large'
          variant='contained'
          loading={isSubmitting}
          loadingPosition='start'
          startIcon={<CheckIcon />}
          disabled={!isValid}
          sx={{ textTransform: 'none' }}
        >
          {isSubmitting ? 'Submitting...' : 'Save'}
        </LoadingButton>
      </Stack>
    </form>
  );
}
