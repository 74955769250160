/* eslint-disable no-nested-ternary */
import { InboundProcess } from '@features/supplyChain/types';
import { useReactHookForm } from '@hooks';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { Text } from '@components/Text';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { validationSchema, makeDefaultValues } from './form';
import { SignOffGoodsReceivedNoteFormValues } from './types';

interface SignOffGoodsReceivedNoteFormProps {
  selectedInboundProcess: InboundProcess;
  onClose: () => void;
  onSubmit: (values: SignOffGoodsReceivedNoteFormValues) => void;
  isDesktop: boolean;
  isTablet: boolean;
}

export function SignOffGoodsReceivedNoteForm({
  selectedInboundProcess,
  onClose,
  onSubmit,
  isDesktop,
  isTablet,
}: SignOffGoodsReceivedNoteFormProps) {
  const { handleSubmit, isSubmitting, isValid } =
    useReactHookForm<SignOffGoodsReceivedNoteFormValues>({
      defaultValues: makeDefaultValues(selectedInboundProcess),
      validationSchema,
    });

  const { userBusinessName, status } = selectedInboundProcess;
  const { createdAt, estimatedTimeOfArrival } =
    selectedInboundProcess.shipmentNote;
  const skus = selectedInboundProcess.goodsReceivedNote?.skus;
  const dateOfArrival = selectedInboundProcess.goodsReceivedNote?.dateOfArrival;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={16}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Client name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{userBusinessName}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Creation date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(createdAt).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Created by
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{userBusinessName}</Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Status
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{status}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Date of arrival
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(dateOfArrival).format('DD/MM/YYYY')}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        {isDesktop && (
          <>
            <Box
              display='grid'
              gridTemplateColumns='2rem repeat(12, 1fr)'
              columnGap='1rem'
              rowGap='1rem'
              alignItems='center'
            >
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '1 / 2', textAlign: 'center' }}
              >
                #
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '2 / 4' }}
              >
                SKU ID
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '4 / 8' }}
              >
                Product name
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '8 / 10' }}
              >
                SKU name
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '10 / 12' }}
              >
                Despatched qty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '12 / 14' }}
              >
                Delivered qty
              </Text>
            </Box>
            <Divider />
          </>
        )}
        <Stack gap={isDesktop ? '1.5rem' : '3rem'}>
          {skus?.map((sku, index) => {
            return (
              <>
                <Box
                  key={sku.skuId}
                  display='grid'
                  gridTemplateColumns='2rem repeat(12, 1fr)'
                  columnGap='1rem'
                  rowGap='1rem'
                  alignItems={isDesktop ? 'center' : 'flex-start'}
                >
                  {/* SKU index  */}
                  <Stack
                    gridColumn={isDesktop ? '1 / 2' : '1 / 2'}
                    justifyContent='center'
                  >
                    <Chip label={index + 1} />
                  </Stack>
                  {/* SKU ID */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '2 / 4' : isTablet ? 'span 5' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        SKU ID
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {sku.skuIdentifier}
                    </Text>
                  </Stack>
                  {/* Product name */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '4 / 8' : isTablet ? 'span 7' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        Product name
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {sku.productName}
                    </Text>
                  </Stack>
                  {/* Sku name */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '8 / 10' : isTablet ? '2 / 7' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        SKU name
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {sku.name}
                    </Text>
                  </Stack>
                  {/* Despatched quantity */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '10 / 12' : isTablet ? '7 / 14' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        Despatched quantity
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {sku.despatchedSkuQuantity}
                    </Text>
                  </Stack>
                  {/* Delivered quantity */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '12 / 14' : isTablet ? '7 / 14' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        Delivered quantity
                      </Text>
                    )}
                    <Text
                      as='p'
                      size='md'
                      css={{
                        color:
                          sku.receivedSkuQuantity < sku.despatchedSkuQuantity
                            ? 'var(--colors-tomato9)'
                            : 'var(--colors-gray12)',
                      }}
                    >
                      {sku.receivedSkuQuantity}
                    </Text>
                  </Stack>
                  {/* Discrepancy reason */}
                  {sku.discrepancyReason && (
                    <Stack
                      gap={4}
                      gridColumn={
                        isDesktop ? '8 / 14' : isTablet ? '7 / 14' : '2 / 14'
                      }
                    >
                      <Text as='p' size='sm' weight='semibold'>
                        Discrepancy reason
                      </Text>
                      <Text as='p' size='md'>
                        {sku.discrepancyReason}
                      </Text>
                    </Stack>
                  )}
                </Box>
                {index !== skus.length - 1 && <Divider />}
              </>
            );
          })}
        </Stack>
      </Stack>
      {selectedInboundProcess.goodsReceivedNote?.comment && (
        <Stack spacing={8} p={6} sx={{ mb: 16 }}>
          <Text as='h3' size='lg' weight='bold'>
            Comments
          </Text>
          <Text as='p' size='md' css={{ mb: '$space16' }}>
            {selectedInboundProcess.goodsReceivedNote?.comment}
          </Text>
        </Stack>
      )}
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={onClose}
        >
          Close
        </Button>
        {selectedInboundProcess.goodsReceivedNote?.finalisedAt === null && (
          <LoadingButton
            type='submit'
            size='large'
            variant='contained'
            loading={isSubmitting}
            loadingPosition='start'
            startIcon={<CheckIcon />}
            disabled={!isValid}
            sx={{ textTransform: 'none' }}
          >
            {isSubmitting ? 'Saving...' : 'Sign-off'}
          </LoadingButton>
        )}
      </Stack>
    </form>
  );
}
