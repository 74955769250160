import { Heading } from '@components/Heading';
import { Text } from '@components/Text';
import { View } from '@views/View';
import { useAuth } from '@context';
import { useAtomValue } from 'jotai';
import { useMediaQuery } from 'usehooks-ts';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Stack, TextField } from '@mui/material';
import { Button } from '@components/Button';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@components/Icon';
import { MdCheck } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { DialogModal } from '@components/DialogModal';
import { Enable2FAForm } from '@forms/Enable2FAForm/Enable2FAForm';
import {
  BUSINESS_PROFILE_CREATE,
  BUSINESS_PROFILE_UPDATE,
} from '../../constants';
import { useAuthHttp } from '../../hooks';
import { businessProfileAtom } from '../../store/jotai';

const schema = Yup.object({
  businessNumber: Yup.string().required('Company number is required'),
  businessAddress: Yup.string().required('Registered address is required'),
  vatNumber: Yup.string().nullable(),
  businessIndustry: Yup.string().nullable(),
  businessWebsite: Yup.string().nullable(),
  businessContactName: Yup.string().required('Contact name is required'),
  businessContactPhoneNumber: Yup.string()
    .matches(/^(?:0|\+?44)(?:\d\s?){9,10}$/g, {
      message: 'Please enter a valid phone number',
      excludeEmptyString: true,
    })
    .required('Business contact phone number is required'),
  businessContactEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Business contact email is required'),
});

export type HQFormValues = {
  businessName: string;
  businessNumber: string;
  businessAddress: string;
  vatNumber: string;
  businessIndustry: string;
  businessWebsite: string;
  businessContactName: string;
  businessContactPhoneNumber: string;
  businessContactEmail: string;
};

export type BusinessProfile = {
  businessProfile: {
    id: string;
    businessType: string;
    businessSize: string;
    contactName: string;
    targetCustomerType: string;
    numberOfPalletsInAndOutPerWeek: number;
    averageShipmentSizePerWeek: number;
    averageShipmentType: string;
    teamSize: string;
    address: string;
    companyNumber: string;
    industry: string;
    website: string;
    contactEmail: string;
    contactPhoneNumber: string;
    vatNumber: string;
    warehouseTandCAgreement: {
      hasAgreed: boolean;
      agreedById: string;
      agreedByEmailAddress: string;
      agreedByName: string;
      agreementDate: string;
    };
  };
};

export function Profile() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const { user } = useAuth();
  const authHttp = useAuthHttp();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isHQFormSubmitting, setIsHQFormSubmitting] = useState(false);
  const [hasHQFormSubmitted, setHasHQFormSubmitted] = useState(false);
  const businessProfile = useAtomValue(businessProfileAtom);
  const [is2faModalOpen, setIs2faModalOpen] = useState(false);
  const [haveSetUp2Fa, setHaveSetUp2Fa] = useState(
    user?.twoFactorMethodIds !== undefined && user.twoFactorMethodIds.length > 0
  );

  const warehouseIDParam = searchParams.get('warehouseID') || '';

  const defaultValues = {
    businessName: user?.businessName || '',
    businessNumber: businessProfile?.companyNumber || '',
    businessAddress: businessProfile?.address || '',
    businessIndustry: businessProfile?.industry || '',
    businessWebsite: businessProfile?.website || '',
    businessContactName: businessProfile?.contactName || '',
    businessContactPhoneNumber: businessProfile?.contactPhoneNumber || '',
    businessContactEmail: businessProfile?.contactEmail || '',
    vatNumber: businessProfile?.vatNumber || '',
  };

  const methods = useForm<HQFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { ...defaultValues },
  });
  const { handleSubmit, control, reset } = methods;

  const handleHQFormSubmit = async (values: HQFormValues) => {
    setIsHQFormSubmitting(true);
    try {
      const { data } = await authHttp.post<BusinessProfile>(
        businessProfile ? BUSINESS_PROFILE_UPDATE : BUSINESS_PROFILE_CREATE,
        {
          businessProfile: {
            address: values.businessAddress,
            companyNumber: values.businessNumber,
            industry: values.businessIndustry,
            website: values.businessWebsite,
            contactName: values.businessContactName,
            contactEmail: values.businessContactEmail,
            contactPhoneNumber: values.businessContactPhoneNumber,
            vatNumber: values.vatNumber,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data) {
        setHasHQFormSubmitted(true);
        if (warehouseIDParam) navigate(`/warehouse/${warehouseIDParam}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsHQFormSubmitting(false);
    }
  };

  useEffect(() => {
    reset({
      businessNumber: businessProfile?.companyNumber || '',
      businessAddress: businessProfile?.address || '',
      businessIndustry: businessProfile?.industry || '',
      businessWebsite: businessProfile?.website || '',
      businessContactName: businessProfile?.contactName || '',
      businessContactPhoneNumber: businessProfile?.contactPhoneNumber || '',
      businessContactEmail: businessProfile?.contactEmail || '',
      vatNumber: businessProfile?.vatNumber || '',
    });
  }, [businessProfile]);

  return (
    <View>
      <Stack spacing={36}>
        <Stack spacing={12}>
          <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
            Profile
          </Heading>
          <Text>
            <strong>Business name: </strong>
            {user?.businessName}
          </Text>
          <Text>
            <strong>Name: </strong>
            {`${user?.firstName} ${user?.lastName}`}
          </Text>
          <Text>
            <strong>Email: </strong>
            {user?.email}
          </Text>
          <Text>
            <strong>Mobile phone number: </strong>
            {user?.mobilePhoneNumber}
          </Text>
          {!haveSetUp2Fa && (
            <Stack spacing={16} width={isDesktop ? '33%' : '100%'}>
              <Button onClick={() => setIs2faModalOpen(true)} size='sm'>
                Enable 2FA
              </Button>
            </Stack>
          )}
          {haveSetUp2Fa && (
            <Text>2 Factor Authentication is enabled for this account</Text>
          )}
          <Stack
            spacing={16}
            width={isDesktop ? '33%' : '100%'}
            justifyContent='center'
            alignItems='center'
          >
            <DialogModal
              open={is2faModalOpen}
              setOpen={setIs2faModalOpen}
              onClose={() => setIs2faModalOpen(false)}
              title='Enable 2 Factor Authentication'
              id='enable-2fa-modal'
              fullWidth={false}
            >
              <Enable2FAForm
                haveSetUp2Fa={haveSetUp2Fa}
                setHaveSetUp2Fa={setHaveSetUp2Fa}
              />
            </DialogModal>
          </Stack>
        </Stack>
        {user?.roles.includes('BusinessUserManager') && (
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(handleHQFormSubmit)}
              noValidate
              style={{ width: '100%' }}
            >
              <Stack spacing={16} width={isDesktop ? '33%' : '100%'}>
                <Heading as='h3' size='xs'>
                  Business Profile
                </Heading>
                {/* <Controller
                  name='businessName'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Company name *'
                      placeholder='Company name *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                /> */}
                <Controller
                  name='businessNumber'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Company number *'
                      placeholder='Company number *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='businessAddress'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Registered address *'
                      placeholder='Registered address *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='vatNumber'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='VAT'
                      placeholder='GB123 456 789'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='businessIndustry'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Company industry'
                      placeholder='Company industry'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='businessWebsite'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Corporate website'
                      placeholder='Corporate website'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='businessContactName'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Contact Name *'
                      placeholder='Contact Name *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='businessContactPhoneNumber'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Corporate contact phone number *'
                      placeholder='Corporate contact phone number *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='businessContactEmail'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Corporate contact email *'
                      placeholder='Corporate contact email *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                {/* <Controller
                  name='businessContactName'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Contact name *'
                      placeholder='Contact name *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                /> */}
                <Button
                  type='submit'
                  rightIcon={<MdCheck size='1.25rem' />}
                  isLoading={isHQFormSubmitting}
                  loadingIcon={<Icon name='loading' />}
                  loadingText='Submitting...'
                  size='sm'
                  action='cta'
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {hasHQFormSubmitted
                    ? 'Submitted'
                    : businessProfile
                    ? 'Update'
                    : 'Submit'}
                </Button>
              </Stack>
            </form>
          </FormProvider>
        )}
      </Stack>
    </View>
  );
}
