/* eslint-disable no-nested-ternary */
import { InboundProcess } from '@features/supplyChain/types';
import { useReactHookFormWithFieldArray } from '@hooks';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { Text } from '@components/Text';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { validationSchema, makeDefaultValues } from './form';
import { CreatDraftGoodsReceivedNoteFormValues } from './types';

interface CreateGoodsReceivedNoteFormProps {
  selectedInboundProcess: InboundProcess;
  onClose: () => void;
  onSubmit: (values: CreatDraftGoodsReceivedNoteFormValues) => void;
  isDesktop: boolean;
  isTablet: boolean;
}

export function CreateDraftGoodsReceivedNoteForm({
  selectedInboundProcess,
  onClose,
  onSubmit,
  isDesktop,
  isTablet,
}: CreateGoodsReceivedNoteFormProps) {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    isSubmitting,
    isValid,
    fields: skusFields,
    watch,
  } = useReactHookFormWithFieldArray<CreatDraftGoodsReceivedNoteFormValues>({
    defaultValues: makeDefaultValues(selectedInboundProcess),
    validationSchema,
    fieldArrayName: 'skus',
  });

  const { userBusinessName, status } = selectedInboundProcess;
  const { createdAt, estimatedTimeOfArrival } =
    selectedInboundProcess.shipmentNote;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={16}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Client name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{userBusinessName}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Creation date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(createdAt).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Created by
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{userBusinessName}</Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Status
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{status}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Expected Date of arrival
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(estimatedTimeOfArrival).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Actual Date of arrival
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              <Controller
                name='actualDateOfArrival'
                control={control}
                render={({ field, fieldState: { error, isTouched } }) => {
                  return (
                    <DatePicker
                      format='DD/MM/YYYY'
                      minDate={dayjs(new Date())}
                      inputRef={field.ref}
                      onChange={(value) => {
                        setValue('actualDateOfArrival', dayjs(value).format(), {
                          shouldValidate: true,
                        });
                      }}
                      defaultValue={dayjs(estimatedTimeOfArrival)}
                      slotProps={{
                        textField: {
                          onBlur: field.onBlur,
                          name: field.name,
                          multiline: !isDesktop,
                          ...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          }),
                          error: Boolean(error && isTouched),
                          helperText: Boolean(error) && error?.message,
                        },
                      }}
                    />
                  );
                }}
              />
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        <Stack gap={isDesktop ? '1.5rem' : '3rem'}>
          {skusFields.map((skusField, index) => {
            const despatchedQuantity = watch(
              `skus.${index}.despatchedQuantity`
            );
            const receivedSkuQuantity = watch(
              `skus.${index}.receivedSkuQuantity`
            );

            const isDiscrepancy =
              receivedSkuQuantity !== undefined &&
              despatchedQuantity !== receivedSkuQuantity;

            return (
              <Box
                key={skusField.id}
                display='grid'
                gridTemplateColumns='2rem repeat(12, 1fr)'
                gridTemplateRows={
                  isDesktop
                    ? `repeat(${isDiscrepancy ? '2' : '1'}, min-content)`
                    : isDiscrepancy
                    ? 'repeat(3, min-content)'
                    : 'repeat(2, min-content)'
                }
                columnGap='1rem'
                rowGap='1rem'
              >
                {/* SKU index  */}
                <Stack
                  gridColumn={isDesktop ? 'span 1' : '1 / 2'}
                  justifyContent='center'
                >
                  <Chip label={index + 1} />
                </Stack>
                {/* SKU ID */}
                <Box
                  gridColumn={
                    isDesktop ? 'span 2' : isTablet ? 'span 6' : '2 / 14'
                  }
                >
                  <TextField
                    size='small'
                    value={skusField.skuIdentifier}
                    color='primary'
                    label='SKU ID'
                    fullWidth
                    inputProps={{
                      disabled: true,
                      sx: {
                        color: 'primary',
                        cursor: 'not-allowed',
                      },
                    }}
                  />
                </Box>
                {/* Product name */}
                <Box
                  gridColumn={
                    isDesktop ? 'span 4' : isTablet ? 'span 6' : 'span 13'
                  }
                >
                  <TextField
                    size='small'
                    value={`${skusField.productName} (${skusField.skuUnitOfMeasure})`}
                    color='primary'
                    label='Product name'
                    fullWidth
                    inputProps={{
                      disabled: true,
                      sx: {
                        color: 'primary',
                        cursor: 'not-allowed',
                      },
                    }}
                  />
                </Box>
                {/* Despatched quantity */}
                <Box
                  gridColumn={
                    isDesktop ? 'span 3' : isTablet ? '2 / 8' : 'span 13'
                  }
                >
                  <TextField
                    size='small'
                    value={skusField.despatchedQuantity}
                    color='primary'
                    label='Despatched Qty'
                    fullWidth
                    inputProps={{
                      disabled: true,
                      sx: {
                        color: 'primary',
                        cursor: 'not-allowed',
                      },
                    }}
                  />
                </Box>
                {/* Delivered quantity */}
                <Box
                  gridColumn={
                    isDesktop ? 'span 3' : isTablet ? '8 / 14' : 'span 13'
                  }
                >
                  {/* Delivered quantity */}
                  <Controller
                    name={`skus.${index}.receivedSkuQuantity`}
                    control={control}
                    render={({ field, fieldState: { error, isTouched } }) => {
                      return (
                        <TextField
                          size='small'
                          inputProps={{
                            min: 0,
                            type: 'number',
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                          name={field.name}
                          value={field.value}
                          onBlur={field.onBlur}
                          inputRef={field.ref}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const value = parseInt(event.target.value, 10);
                            field.onChange(value);
                            if (value === despatchedQuantity) {
                              setValue(`skus.${index}.discrepancyReason`, null);
                            }
                          }}
                          sx={{ minWidth: '12rem' }}
                          error={Boolean(error && isTouched)}
                          helperText={
                            Boolean(error && isTouched) &&
                            'A delivered quantity is required.'
                          }
                          variant='outlined'
                          label='Delivered Qty (required)'
                          fullWidth
                          placeholder='Delivered Qty'
                          InputLabelProps={{ shrink: true }}
                          color='primary'
                        />
                      );
                    }}
                  />
                </Box>
                {/* Discrepancy reason */}
                {isDiscrepancy && (
                  <Box
                    gridColumn={
                      isDesktop ? '8 / 14' : isTablet ? '2 / 14' : 'span 13'
                    }
                  >
                    <Controller
                      name={`skus.${index}.discrepancyReason`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => {
                        return (
                          <TextField
                            size='small'
                            name={field.name}
                            onBlur={field.onBlur}
                            inputRef={field.ref}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              field.onChange(event.target.value);
                            }}
                            multiline
                            minRows={2}
                            error={Boolean(error && isTouched)}
                            helperText={
                              Boolean(error && isTouched) && error?.message
                            }
                            label='Discrepancy reason (required)'
                            fullWidth
                            placeholder='Discrepancy reason (required)'
                            {...(!isDesktop && {
                              InputLabelProps: {
                                shrink: true,
                              },
                            })}
                            variant='outlined'
                            sx={{
                              '.Mui-disabled': { cursor: 'not-allowed' },
                            }}
                          />
                        );
                      }}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          Comment
        </Text>
        <Controller
          name='comment'
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              {...field}
              multiline
              minRows={4}
              error={Boolean(error && isTouched)}
              helperText={Boolean(error && isTouched) && error?.message}
              label='Comment (optional)'
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
              variant='outlined'
            />
          )}
        />
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={onClose}
        >
          Discard
        </Button>
        <LoadingButton
          type='submit'
          size='large'
          variant='contained'
          loading={isSubmitting}
          loadingPosition='start'
          startIcon={<CheckIcon />}
          disabled={!isValid}
          sx={{ textTransform: 'none' }}
        >
          {isSubmitting ? 'Submitting...' : 'Received'}
        </LoadingButton>
      </Stack>
    </form>
  );
}
