/* eslint-disable no-nested-ternary */
import { OutboundProcess } from '@features/supplyChain/types';
import { useReactHookFormWithFieldArray } from '@hooks';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import { Controller } from 'react-hook-form';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Text } from '@components/Text';
import Divider from '@mui/material/Divider';
import { ChangeEvent } from 'react';
import dayjs from 'dayjs';
import { PalletCalculator } from '@components/PalletCalculator';
import { CreateOutboundShipmentNoteFormValues } from './types';
import { validationSchema, makeDefaultValues } from './form';

function QuantitySpacer() {
  return <span>&nbsp;</span>;
}

interface CreateOutboundShipmentNoteFormProps {
  selectedOutboundProcess: OutboundProcess;
  onClose: () => void;
  onSubmit: (values: CreateOutboundShipmentNoteFormValues) => void;
  isDesktop: boolean;
  isTablet: boolean;
}

export function CreateOutboundShipmentNoteForm({
  selectedOutboundProcess,
  onClose,
  onSubmit,
  isDesktop,
  isTablet,
}: CreateOutboundShipmentNoteFormProps) {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    isSubmitting,
    isValid,
    fields: skusFields,
  } = useReactHookFormWithFieldArray<CreateOutboundShipmentNoteFormValues>({
    defaultValues: makeDefaultValues(selectedOutboundProcess),
    validationSchema,
    fieldArrayName: 'skus',
  });

  const { despatchNote } = selectedOutboundProcess;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={8}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Shipment note #
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {despatchNote.despatchNoteNumber.replace('-DN', '-SN')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Recipient name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {despatchNote.customerName}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Recipient address
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>123 Main St</Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Shipped with
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>ABC Haulage</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Required time of arrival
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(despatchNote.requiredTimeOfArrival).format(
                'DD/MM/YYYY, H:mm'
              )}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              ETA
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(despatchNote.requiredTimeOfArrival)
                .add(1, 'day')
                .format('DD/MM/YYYY, H:mm')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Shipment date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              <Controller
                name='shipmentDate'
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <DatePicker
                      format='DD/MM/YYYY'
                      minDate={dayjs(new Date())}
                      inputRef={field.ref}
                      onChange={(value) => {
                        setValue('shipmentDate', dayjs(value).format(), {
                          shouldValidate: true,
                        });
                      }}
                      defaultValue={dayjs()}
                      slotProps={{
                        textField: {
                          onBlur: field.onBlur,
                          name: field.name,
                          multiline: !isDesktop,
                          ...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          }),
                          error: Boolean(error),
                          helperText: error?.message,
                        },
                      }}
                    />
                  );
                }}
              />
            </Text>
          </Stack>
        </Stack>
      </Stack>
      {/* SKUS */}
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        {isDesktop && (
          <>
            <Box
              display='grid'
              gridTemplateColumns='2rem repeat(12, 1fr)'
              columnGap='1rem'
              rowGap='1rem'
              alignItems='center'
            >
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '1 / 2', textAlign: 'center' }}
              >
                #
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '2 / 4' }}
              >
                SKU ID
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '4 / 6' }}
              >
                Product name
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '6 / 8' }}
              >
                UoM
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '8 / 10' }}
              >
                Picked <br />
                SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '10 / 12' }}
              >
                Despatched <br />
                SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '12 / 14' }}
              >
                Despatched <br />
                Pallet Q-ty
              </Text>
            </Box>
            <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
          </>
        )}
        <Stack gap={isDesktop ? '1.5rem' : '3rem'}>
          {skusFields.map((skusField, index) => {
            const { pickedQuantity } = skusField;
            const despatchedQuantity = watch(
              `skus.${index}.despatchedQuantity`
            );

            const isDiscrepancy =
              despatchedQuantity < pickedQuantity ||
              despatchedQuantity > pickedQuantity;

            const hasPackagingInfo =
              despatchNote.skus.find((s) => s.skuId === skusField.skuId)
                ?.packagingDetails.packagingInfo?.amountOfSkusInLayer &&
              despatchNote.skus.find((s) => s.skuId === skusField.skuId)
                ?.packagingDetails.packagingInfo?.amountOfLayersInPallet;

            let amountOfSkusInLayer: number | undefined;
            let amountOfLayersInPallet: number | undefined;
            let packagingInfoType: string | undefined;
            let palletisingType: string | null | undefined;

            if (hasPackagingInfo) {
              amountOfSkusInLayer = despatchNote.skus.find(
                (s) => s.skuId === skusField.skuId
              )?.packagingDetails.packagingInfo?.amountOfSkusInLayer;

              amountOfLayersInPallet = despatchNote.skus.find(
                (s) => s.skuId === skusField.skuId
              )?.packagingDetails.packagingInfo?.amountOfLayersInPallet;

              packagingInfoType = despatchNote.skus.find(
                (s) => s.skuId === skusField.skuId
              )?.packagingDetails.packagingInfo?.type;
            } else {
              palletisingType = despatchNote.skus.find(
                (s) => s.skuId === skusField.skuId
              )?.packagingDetails.palletisingType;
            }

            return (
              <>
                <Box
                  key={`${skusField.id}-${skusField.skuIdentifier}`}
                  display='grid'
                  gridTemplateColumns='2rem repeat(12, 1fr)'
                  gridTemplateRows={
                    isDesktop
                      ? `repeat(${isDiscrepancy ? '2' : '1'}, min-content)`
                      : isDiscrepancy
                      ? 'repeat(3, min-content)'
                      : 'repeat(2, min-content)'
                  }
                  columnGap='1rem'
                  rowGap='1rem'
                >
                  {/* SKU index  */}
                  <Stack gridColumn='1 / 2' sx={{ alignSelf: 'center' }}>
                    <Chip label={index + 1} />
                  </Stack>
                  {/* SKU ID */}
                  <Stack
                    gridColumn={
                      isDesktop ? '2 / 4' : isTablet ? '2 / 8' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '1' : '1'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        SKU ID
                      </Text>
                    )}
                    <Text>{skusField.skuIdentifier}</Text>
                  </Stack>
                  {/* Product name */}
                  <Stack
                    gridColumn={
                      isDesktop ? '4 / 6' : isTablet ? '2 / 8' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '2' : '2'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        Product name
                      </Text>
                    )}
                    <Text>{skusField.productName}</Text>
                  </Stack>
                  {/* UoM */}
                  <Stack
                    gridColumn={
                      isDesktop ? '6 / 8' : isTablet ? '2 / 8' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '3' : '3'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        UoM
                      </Text>
                    )}
                    <Text>{skusField.name}</Text>
                  </Stack>
                  {/* Picked SKU Quantity */}
                  <Stack
                    gridColumn={
                      isDesktop ? '8 / 10' : isTablet ? 'span 6' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '1' : '4'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        Picked SKU Q-ty
                      </Text>
                    )}
                    <Text>{skusField.pickedQuantity}</Text>
                  </Stack>
                  {/* Despatched SKU Quantity */}
                  <Box
                    gridColumn={
                      isDesktop ? '10 / 12' : isTablet ? '8 / 14' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '2' : '5'}
                    sx={{ alignSelf: 'center' }}
                  >
                    <Controller
                      name={`skus.${index}.despatchedQuantity`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => (
                        <TextField
                          onBlur={field.onBlur}
                          name={field.name}
                          value={field.value}
                          ref={field.ref}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const despatchedQuantityValue = parseInt(
                              e.target.value,
                              10
                            );

                            setValue(
                              `skus.${index}.despatchedQuantity`,
                              despatchedQuantityValue,
                              {
                                shouldValidate: true,
                              }
                            );

                            if (despatchedQuantityValue === pickedQuantity) {
                              setValue(`skus.${index}.discrepancyReason`, '');
                            }
                          }}
                          variant='outlined'
                          size='small'
                          error={Boolean(error)}
                          helperText={Boolean(error) && error?.message}
                          color='primary'
                          label='Despatched SKU Q-ty (required)'
                          placeholder='Despatched SKU Q-ty (required)'
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                            // sx: {
                            //   fontSize: 'var(--fontSizes-textLg)',
                            //   fontWeight: 'var(--fontWeights-bold)',
                            //   lineHeight: 'var(--lineHeights-textSm)',
                            //   color: 'var(--colors-gray12)',
                            //   '&.Mui-focused, &.Mui-error': {
                            //     color: 'var(--colors-gray12)',
                            //   },
                            // },
                          }}
                          inputProps={{
                            sx: {
                              color: 'primary',
                            },
                            type: 'number',
                            min: 0,
                          }}
                        />
                      )}
                    />
                  </Box>
                  {/* Despatched Pallet Quantity */}
                  {hasPackagingInfo ? (
                    <Stack
                      gridColumn={
                        isDesktop ? '12 / 14' : isTablet ? '8 / 14' : '2 / 14'
                      }
                      gridRow={isDesktop ? '1' : isTablet ? '3' : '6'}
                      spacing={8}
                      sx={{ alignSelf: 'center' }}
                    >
                      {!isDesktop && (
                        <Text size='sm' weight='bold'>
                          Despatched Pallet Q-ty
                        </Text>
                      )}
                      <Text css={{ whiteSpace: 'nowrap' }}>
                        {despatchedQuantity ? (
                          <PalletCalculator
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            palletName={packagingInfoType!}
                            skuQuantity={despatchedQuantity}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            amountOfSkusInLayer={amountOfSkusInLayer!}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            amountOfLayersInPallet={amountOfLayersInPallet!}
                          />
                        ) : (
                          <QuantitySpacer />
                        )}
                      </Text>
                    </Stack>
                  ) : (
                    <Stack
                      gridColumn={
                        isDesktop ? '12 / 14' : isTablet ? '8 / 14' : '2 / 14'
                      }
                      gridRow={isDesktop ? '1' : isTablet ? '3' : '6'}
                      spacing={8}
                      sx={{ alignSelf: 'center' }}
                    >
                      {!isDesktop && (
                        <Text size='sm' weight='bold'>
                          Despatched Pallet Q-ty
                        </Text>
                      )}
                      <Text css={{ whiteSpace: 'nowrap' }}>
                        {palletisingType}
                      </Text>
                    </Stack>
                  )}
                  {/* Discrepancy Reason */}
                  {isDiscrepancy && (
                    <Box
                      gridColumn={
                        isDesktop ? '10 / 14' : isTablet ? '8 / 14' : '2 / 14'
                      }
                      gridRow={isDesktop ? '2' : isTablet ? '4' : '7'}
                      sx={{ alignSelf: 'center' }}
                    >
                      <Controller
                        name={`skus.${index}.discrepancyReason`}
                        control={control}
                        render={({
                          field,
                          fieldState: { error, isTouched },
                        }) => (
                          <TextField
                            {...field}
                            variant='outlined'
                            size='small'
                            error={Boolean(error)}
                            helperText={Boolean(error) && error?.message}
                            color='primary'
                            label='Discrepancy reason (required)'
                            fullWidth
                            multiline
                            minRows={2}
                            InputLabelProps={{
                              shrink: true,
                              // sx: {
                              //   fontSize: 'var(--fontSizes-textLg)',
                              //   fontWeight: 'var(--fontWeights-bold)',
                              //   lineHeight: 'var(--lineHeights-textSm)',
                              //   color: 'var(--colors-gray12)',
                              //   '&.Mui-focused, &.Mui-error': {
                              //     color: 'var(--colors-gray12)',
                              //   },
                              // },
                            }}
                            inputProps={{
                              sx: {
                                color: 'primary',
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                  )}
                </Box>
                {index !== skusFields.length - 1 && (
                  <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
                )}
              </>
            );
          })}
        </Stack>
      </Stack>
      {/* Comment */}
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          Comment
        </Text>
        <Controller
          name='comment'
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              {...field}
              multiline
              minRows={4}
              error={Boolean(error && isTouched)}
              helperText={Boolean(error && isTouched) && error?.message}
              label='Comment (optional)'
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
              variant='outlined'
            />
          )}
        />
      </Stack>
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          fullWidth={!isDesktop}
          onClick={onClose}
        >
          Discard
        </Button>
        <LoadingButton
          type='submit'
          size='large'
          variant='contained'
          loading={isSubmitting}
          loadingPosition='start'
          startIcon={<CheckIcon />}
          disabled={!isValid}
          sx={{ textTransform: 'none' }}
          fullWidth={!isDesktop}
        >
          {isSubmitting ? 'Saving...' : 'Confirm'}
        </LoadingButton>
      </Stack>
    </form>
  );
}
